import { DOMAIN } from "../utils/settings/config";
import authorizedAxiosInstance from "./authorizedAxios";

const layDanhSachNguonHangAPI = async () => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/nhan_don/lay_danh_sach_nguon_hang`);
}

const layDanhSachOrderAPI = async (
    page = 1,
    pageSize = 30,
    idNguonHang = '',
    maOrder = '',
    trackingNumber = '',

) => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/nhan_don/lay_danh_sach_order`, {
        idNguonHang,
        maOrder,
        trackingNumber,
        page,
        pageSize
    });
}

const layOrderChiTietAPI = async (id) => {
    return await authorizedAxiosInstance.get(`${DOMAIN}/nhan_don/lay_chi_tiet_order/${id}`);
}

const chinhSuaOrderAPI = async (idOrder, idDonHang, idTrangThai, canNang, tongTienCan) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/nhan_don/thay_doi_tinh_trang_don_hang`, {
        idOrder,
        idDonHang,
        idTrangThai,
        canNang,
        tongTienCan
    })
}

const xacNhanDaNhanHangAPI = async (id) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/nhan_don/thay_doi_tinh_trang_order/${id}`);
}

const thayDoiCanNangNhapAPI = async (idOrder = 0, tongCanNang = 0) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/nhan_don/thay_doi_order`, {
        idOrder,
        tongCanNang
    });

}

export const nhanDonService = {
    layDanhSachNguonHangAPI,
    layDanhSachOrderAPI,
    layOrderChiTietAPI,
    chinhSuaOrderAPI,
    xacNhanDaNhanHangAPI,
    thayDoiCanNangNhapAPI
}