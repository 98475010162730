import { DOMAIN } from "../utils/settings/config";
import authorizedAxiosInstance from "./authorizedAxios";
import dayjs from "dayjs"


const layDanhDonShipPhanTrangAPI = async (
    page = 1,
    pageSize = 30,
    startDate = '',
    endDate = '',
    maVanDon = '') => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/don_ship/lay_danh_sach_don_ship`, {
        maVanDon,
        startDate,
        endDate,
        page,
        pageSize
    });
}

const layDanhSachDonHangPhanTrangAPI = async (
    page = 1,
    pageSize = 30,
    idTinhTrang = '',
    maDonHang = '',
    soDienThoai = '',
    tenKhachHang = '',
    startDate = '',
    endDate = '') => {
    return await authorizedAxiosInstance.post(`${DOMAIN}/don_ship/lay_danh_sach_don_hang`, {
        maDonHang,
        soDienThoai,
        tenKhachHang,
        idTinhTrang,
        startDate,
        endDate,
        page,
        pageSize
    });
}

const layDanhSachDonViVanChuyenAPI = async () => {
    return authorizedAxiosInstance.get(`${DOMAIN}/don_ship/lay_danh_sach_don_vi_van_chuyen`);
}

const taoDonShipAPI = async (
    idDonViVanChuyen = "",
    maVanDon = "",
    tienCOD = '',
    phiShip = '',
    idKhachHang = '',
    danhSachDonHang = [],
    ngayShip = '',
    diaChiNhanHang = '') => {

    return await authorizedAxiosInstance.post(`${DOMAIN}/don_ship/them_don_ship`, {
        idDonViVanChuyen,
        maVanDon,
        tienCOD,
        phiShip,
        idKhachHang,
        danhSachDonHang,
        ngayShip,
        diaChiNhanHang,
    })
}

const xoaDonShipAPI = async (id) => {
    return authorizedAxiosInstance.delete(`${DOMAIN}/don_ship/xoa_don_ship/${id}`);
}

const layDonShipChiTietAPI = async (id) => {
    return authorizedAxiosInstance.get(`${DOMAIN}//don_ship/lay_chi_tiet_don_ship/${id}`);
}

const xoaVaThayDoiTrangThaiAPI = async (idDonShip, idDonHang, idTrangThai) => {
    return authorizedAxiosInstance.put(`${DOMAIN}/don_ship/xoa_thay_doi_trang_thai_don_ship`, {
        idDonShip,
        idDonHang,
        idTrangThai
    })
}

const chinhSuaDonShipAPI = async (
    idDonViVanChuyen = "",
    maVanDon = "",
    tienCOD = '',
    phiShip = '',
    idKhachHang = '',
    danhSachDonHang = [],
    ngayShip = '',
    idDonShip = '',
    diaChiNhanHang = "",
) => {
    ngayShip = dayjs(ngayShip).format("YYYY-MM-DD");

    return await authorizedAxiosInstance.put(`${DOMAIN}/don_ship/sua_don_ship/${idDonShip}`, {
        idDonViVanChuyen,
        maVanDon,
        tienCOD,
        phiShip,
        idKhachHang,
        danhSachDonHang,
        ngayShip,
        diaChiNhanHang,
    })
}

const chinhSuaTrangThaiDonShipAPI = async (id, idTrangThai) => {
    return await authorizedAxiosInstance.put(`${DOMAIN}/don_ship/sua_don_ship_thay_doi_trang_thai_don_hang`, {
        id,
        idTrangThai
    })
}

const layDanhSachTinhTrangDonShipAPI = async () => {
    return authorizedAxiosInstance.get(`${DOMAIN}/don_ship/lay_danh_sach_tinh_trang_don_ship`);
}

const taoDonShipChuaShipAPI = async (
    idDonViVanChuyen = "",
    maVanDon = "",
    tienCOD = '',
    phiShip = '',
    idKhachHang = '',
    danhSachDonHang = [],
    ngayShip = '',
    diaChiNhanHang = '') => {

    return await authorizedAxiosInstance.post(`${DOMAIN}/don_ship/tao_don_ship_chua_ship_di`, {
        idDonViVanChuyen,
        maVanDon,
        tienCOD,
        phiShip,
        idKhachHang,
        danhSachDonHang,
        ngayShip,
        diaChiNhanHang,
    })
}

export const donShipService = {
    layDanhDonShipPhanTrangAPI,
    layDanhSachDonHangPhanTrangAPI,
    layDanhSachDonViVanChuyenAPI,
    taoDonShipAPI,
    xoaDonShipAPI,
    layDonShipChiTietAPI,
    xoaVaThayDoiTrangThaiAPI,
    chinhSuaDonShipAPI,
    chinhSuaTrangThaiDonShipAPI,
    layDanhSachTinhTrangDonShipAPI,
    taoDonShipChuaShipAPI,
}