import React, { useState } from "react";
import { DatePicker, Select, Button, Form, Table, message } from "antd";
import dayjs from "dayjs";
import { thongKeBaoCaoService } from "../../services/thongKeBaoCaoService";
import { Notification } from "../../utils/Notifications/Notification";
import { NOTIFICATION_TYPE } from "../../utils/settings/config";

const { RangePicker } = DatePicker;
const { Option } = Select;

const ThongKeDoanhThuChiPhi = () => {
  const [dateRange, setDateRange] = useState([]);
  const [loaiThongKe, setLoaiThongKe] = useState("day");
  const [duLieuDoanhThu, setDuLieuDoanhThu] = useState([]);
  const [tongDon, setTongSon] = useState(0);
  const [doanhThu, setDoanhThu] = useState(0);
  const [chiPhi, setChiPhi] = useState(0);
  const [tongSoSanPham, setTongSoSanPham] = useState(0);

  const handleDateRangeChange = (dates) => {
    setDateRange(dates);
  };

  const handleLoaiThongKeChange = (value) => {
    setLoaiThongKe(value);
  };

  const handleSubmit = async () => {
    if (!dateRange.length) {
      Notification(NOTIFICATION_TYPE.error, "Vui Lòng Chọn Thời Gian");

      return;
    }

    const ngayBatDau = dayjs(dateRange[0]).format("YYYY-MM-DD");
    const ngayKetThuc = dayjs(dateRange[1]).format("YYYY-MM-DD");

    let duongDanApi = "";

    switch (loaiThongKe) {
      case "day":
        duongDanApi = `/thong_ke_bao_cao/danh_sach_doanh_thu_chi_phi_theo_ngay`;
        break;
      case "week":
        duongDanApi = `/thong_ke_bao_cao/danh_sach_doanh_thu_chi_phi_theo_tuan`;
        break;
      case "month":
        duongDanApi = `/thong_ke_bao_cao/danh_sach_doanh_thu_chi_phi_theo_thang`;
        break;
      case "year":
        duongDanApi = `/thong_ke_bao_cao/danh_sach_doanh_thu_chi_phi_theo_nam`;
        break;
      default:
        duongDanApi = `/thong_ke_bao_cao/danh_sach_doanh_thu_chi_phi_theo_ngay`;
    }

    try {
      const { data, status } =
        await thongKeBaoCaoService.laythongKeDoanhThuChiPhiAPI(
          duongDanApi,
          ngayBatDau,
          ngayKetThuc
        );
      console.log(data?.data);
      setDuLieuDoanhThu(data?.data?.data);
      let tmpTongDon = 0;
      let tmpDoanhThu = 0;
      let tmpChiPhi = 0;
      let tmpTongSanPham = 0;
      data?.data?.data.forEach((item) => {
        tmpTongDon += item?.soDon || 0;
        tmpDoanhThu += item?.doanhThu || 0;
        tmpChiPhi += item?.chiPhi || 0;
        tmpTongSanPham += item?.soSanPham || 0;
      });
      setChiPhi(tmpChiPhi);
      setTongSon(tmpTongDon);
      setDoanhThu(tmpDoanhThu);
      setTongSoSanPham(tmpTongSanPham);
    } catch (error) {
      Notification(
        NOTIFICATION_TYPE.error,
        "Không thể lấy dữ liệu",
        error?.response?.data?.message
      );
    }
  };

  const layCotBang = () => {
    return [
      {
        title: "STT",
        key: "stt",
        render: (text, record, index) => index + 1,
        width: 10,
      },
      {
        title: "Mốc thời gian",
        dataIndex: "thoiGian",
        key: "thoiGian",
      },
      {
        title: "Số đơn",
        dataIndex: "soDon",
        key: "soDon",
      },
      {
        title: "Số Sản Phẩm",
        dataIndex: "soSanPham",
        key: "soSanPham",
      },
      {
        title: "Doanh thu",
        dataIndex: "doanhThu",
        key: "doanhThu",
        render: (text) =>
          text
            ?.toLocaleString("vi-VN", {
              style: "currency",
              currency: "VND",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: true,
            })
            .replace(/\./g, ","),
        align: "right",
      },
      {
        title: "Chi Phí",
        dataIndex: "chiPhi",
        key: "chiPhi",
        render: (text) =>
          text
            ?.toLocaleString("vi-VN", {
              style: "currency",
              currency: "VND",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: true,
            })
            .replace(/\./g, ","),
        align: "right",
      },
    ];
  };

  return (
    <div>
      <h2>Thống Kê Doanh Thu, Chi Phí</h2>
      <Form layout="inline">
        <Form.Item label="Từ ngày">
          <DatePicker
            format={"DD/MM/YYYY"}
            onChange={(date) => handleDateRangeChange([date, dateRange[1]])}
          />
        </Form.Item>
        <Form.Item label="Tới ngày">
          <DatePicker
            format={"DD/MM/YYYY"}
            onChange={(date) => handleDateRangeChange([dateRange[0], date])}
          />
        </Form.Item>
        <Form.Item label="Type">
          <Select defaultValue="day" onChange={handleLoaiThongKeChange}>
            <Option value="day">Ngày</Option>
            <Option value="week">Tuần</Option>
            <Option value="month">Tháng</Option>
            <Option value="year">Năm</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleSubmit}>
            Thống kê
          </Button>
        </Form.Item>
      </Form>

      <Table
        columns={layCotBang()}
        dataSource={duLieuDoanhThu}
        // rowKey="thoiGian"
        pagination={false}
        style={{ marginTop: 20 }}
        summary={() => (
          <Table.Summary.Row>
            <Table.Summary.Cell index={0}></Table.Summary.Cell>
            <Table.Summary.Cell index={1}>Tổng cộng</Table.Summary.Cell>
            <Table.Summary.Cell index={2}>{tongDon}</Table.Summary.Cell>
            <Table.Summary.Cell index={3}>{tongSoSanPham}</Table.Summary.Cell>
            <Table.Summary.Cell index={4} align="right">
              {doanhThu
                .toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                })
                .replace(/\./g, ",")}
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5} align="right">
              {chiPhi
                .toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                })
                .replace(/\./g, ",")}
            </Table.Summary.Cell>
          </Table.Summary.Row>
        )}
      />
    </div>
  );
};

export default ThongKeDoanhThuChiPhi;
