import React, { useState, useEffect } from "react";
import {
  Table,
  Tag,
  Button,
  Popconfirm,
  message,
  Flex,
  Input,
  Tooltip,
} from "antd";
import {
  FormOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { SET_CONTENT_DRAWER } from "../../redux/types/GlobalType";

import { LAY_DANH_SACH_TIEN_TE_SAGA } from "../../redux/types/TienTeType";
import {
  Lay_DANH_SACH_NGUON_HANG_SAGA,
  SET_EDIT_NGUON_HANG,
  TIM_KIEM_NGUON_HANG_SAGA,
  XOA_NGUON_HANG_SAGA,
} from "../../redux/types/NguonHangType";
import FormThemNguonHang from "../../components/Forms/NguonHang/FormThemNguonHang";
import FormEditNguonHang from "../../components/Forms/NguonHang/FormEditNguonHang";

export default function QuanLyNguonHang(props) {
  const [dataSearch, setDataSearch] = useState("");

  const dispatch = useDispatch();
  const { danhSachNguonHang } = useSelector((state) => state.NguonHangReducer);
  const { danhSachTienTe } = useSelector((state) => state.TienTeReducer);

  useEffect(() => {
    dispatch({ type: Lay_DANH_SACH_NGUON_HANG_SAGA });
    dispatch({ type: LAY_DANH_SACH_TIEN_TE_SAGA });
  }, []);

  const cancel = (e) => {
    message.error("Hủy Xóa Nguồn Khoản");
  };

  const [state, setState] = useState({
    filteredInfo: null,
    sortedInfo: null,
  });

  const handleChange = (pagination, filters, sorter) => {
    setState({
      filteredInfo: filters,
      sortedInfo: sorter,
    });
  };

  let { sortedInfo, filteredInfo } = state;
  sortedInfo = sortedInfo || {};
  filteredInfo = filteredInfo || {};

  const columns = [
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
      sorter: (item2, item1) => {
        return item2.Id - item1.Id;
      },
      sortDirections: ["descend"],
    },
    {
      title: "Tên Nguồn Hàng",
      dataIndex: "Ten",
      key: "Ten",
      sorter: (item2, item1) => {
        let value1 = item1.Ten?.trim().toLowerCase();
        let value2 = item2.Ten?.trim().toLowerCase();
        if (value2 < value1) {
          return -1;
        }
        return 1;
      },
    },
    {
      title: "Địa Chỉ",
      key: "Link",
      render: (text, record, index) => {
        return (
          <a target="_blank" href={record?.Link}>
            {record?.Link}
          </a>
        );
      },
    },
    {
      title: "Giá Theo Cân",
      dataIndex: "GiaTheoCan",
      key: "GiaTheoCan",
      render: (text) => {
        const value = parseFloat(text);
        if (!isNaN(value)) {
          return value
            .toLocaleString("vi-VN", {
              style: "currency",
              currency: "VND",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: true,
            })
            .replace(/\./g, ",");
        }
        return text;
      },
      align: "right",
    },
    {
      title: "Tiền Công",
      dataIndex: "TienCong",
      key: "TienCong",
      render: (text) => {
        const value = parseFloat(text);
        if (!isNaN(value)) {
          return value
            .toLocaleString("vi-VN", {
              style: "currency",
              currency: "VND",
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              useGrouping: true,
            })
            .replace(/\./g, ",");
        }
        return text;
      },
      align: "right",
    },
    {
      title: "Ghi Chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
    },
    {
      title: "Loại Tiền Tệ",
      key: "IdLoaiTienTe",
      render: (text, record, index) => {
        return (
          <Flex gap="1px 0" wrap>
            {danhSachTienTe?.map((tienTe, index) => {
              if (tienTe?.Id === record?.IdLoaiTienTe)
                return (
                  <Tag key={index} color="success">
                    {tienTe?.TienTe}
                  </Tag>
                );
              else return <></>;
            })}
          </Flex>
        );
      },
    },
    {
      title: "Chức Năng",
      dataIndex: "",
      key: "x",
      render: (text, record, index) => {
        return (
          <div>
            <button
              className="btn me-2 btn-primary"
              onClick={() => {
                const actionSetEdit = {
                  type: SET_EDIT_NGUON_HANG,
                  nguonHangEdit: record,
                };
                dispatch(actionSetEdit);
                // set content drawer
                const actionSetContentDrawer = {
                  type: SET_CONTENT_DRAWER,
                  title: "Chỉnh Sửa Nguồn Hàng",
                  Component: <FormEditNguonHang />,
                };
                dispatch(actionSetContentDrawer);
              }}
            >
              <Tooltip title="chỉnh sửa nguồn hàng">
                <FormOutlined style={{ fontSize: 17 }} />
              </Tooltip>
            </button>
            <Popconfirm
              title="Xóa Khách Hàng"
              description={`Bạn có chắc chắn muốn xóa Nguồn Hàng "${record?.Ten}"?`}
              onConfirm={() => {
                dispatch({
                  type: XOA_NGUON_HANG_SAGA,
                  id: record?.Id,
                });
              }}
              onCancel={cancel}
              okText="Có"
              cancelText="Không"
            >
              <button className="btn btn-danger ml-2" danger>
                <Tooltip title="xóa nguồn hàng">
                  <i className="fa-solid fa-trash"></i>
                </Tooltip>
              </button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const renderSearch = () => {
    return (
      <div className="my-4 mt-3 ms-md-4 row">
        <Input
          onChange={(e) => {
            setDataSearch(e.target.value);
          }}
          value={dataSearch}
          className="col-md-7"
          placeholder="Tìm kiếm nguồn hàng ...."
          prefix={<SearchOutlined />}
        />
        <Button
          onClick={() => {
            if (dataSearch.trim() === "") {
              dispatch({ type: Lay_DANH_SACH_NGUON_HANG_SAGA }); // Hiển thị tất cả
            } else {
              dispatch({
                type: TIM_KIEM_NGUON_HANG_SAGA,
                keyword: dataSearch,
              });
            }
          }}
          className="col-md-2 ml-3"
          type="primary"
        >
          Tìm Kiếm
        </Button>
        <Button
          onClick={() => {
            setDataSearch(""); // Clear input
            dispatch({ type: Lay_DANH_SACH_NGUON_HANG_SAGA }); // Hiển thị tất cả
          }}
          className="col-md-2 ms-md-2"
          type="default"
          icon={<CloseCircleOutlined />}
        >
          Hủy Tìm Kiếm
        </Button>
      </div>
    );
  };

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Quản Lý Nguồn Hàng</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            dispatch({
              type: SET_CONTENT_DRAWER,
              title: "Thêm Nguồn Hàng Mới",
              Component: <FormThemNguonHang />,
            });
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Nguồn Hàng
        </button>
      </div>

      <div className="ms-3">{renderSearch()}</div>

      <Table
        columns={columns}
        rowKey={"id"}
        dataSource={danhSachNguonHang}
        onChange={handleChange}
        pagination={{ pageSize: 30 }} // Page size 30
      />
    </div>
  );
}
