export const SET_DANH_SACH_DON_SHIP = 'SET_DANH_SACH_DON_SHIP';
export const LAY_DANH_SACH_DON_SHIP_SAGA = 'LAY_DANH_SACH_DON_SHIP_SAGA'


export const THEM_DON_HANG_DA_CHON_TRANG_DON_SHIP = 'THEM_DON_HANG_DA_CHON_TRANG_DON_SHIP'

export const XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP = 'XOA_DON_HANG_DA_CHON_TRANG_DON_SHIP'

export const XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP = 'XOA_HET_DON_HANG_DA_CHON_TRANG_SON_SHIP'

export const RESET_DON_SHIP = 'RESET_DON_SHIP'

export const SET_DANH_SACH_DON_HANG_TRANG_DON_SHIP = 'SET_DANH_SACH_DON_HANG_TRANG_DON_SHIP';

export const LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA = 'LAY_DANH_SACH_DON_HANG_TRANG_DON_SHIP_SAGA'

export const SET_DANH_SACH_DON_VI_VAN_CHUYEN = 'SET_DANH_SACH_DON_VI_VAN_CHUYEN'

export const LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA = 'LAY_DANH_SACH_DON_VI_VAN_CHUYEN_SAGA'

export const THEM_DON_SHIP_SAGA = 'THEM_DON_SHIP_SAGA'

export const XOA_DON_SHIP_SAGA = 'XOA_DON_SHIP_SAGA'

export const SET_DON_SHIP_EDIT = 'SET_DON_SHIP_EDIT'
export const LAY_CHI_TIET_DON_SHIP_SAGA = 'LAY_CHI_TIET_DON_SHIP_SAGA'

export const XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRANG_SHIP_SAGA = 'XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRANG_SHIP_SAGA'

export const CHINH_SUA_DON_SHIP_SAGA = 'CHINH_SUA_DON_SHIP_SAGA'

export const CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA = 'CHINH_SUA_TRANG_THAI_DON_SHIP_SAGA'

export const LAY_CHI_TIET_DON_SHIP_SAGA_V2 = 'LAY_CHI_TIET_DON_SHIP_SAGA_V2'

export const SET_DANH_SACH_TINH_TRANG_DON_SHIP = 'SET_DANH_SACH_TINH_TRANG_DON_SHIP'

export const LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA = 'LAY_DANH_SACH_TINH_TRANG_DON_SHIP_SAGA'

export const TAO_DON_SHIP_CHUA_SHIP_SAGA = 'TAO_DON_SHIP_CHUA_SHIP_SAGA'

export const THEM_VAO_EXPORT = 'THEM_VAO_EXPORT';
export const XOA_KHOI_EXPORT = 'XOA_KHOI_EXPORT';
export const CLEAR_EXPORT = 'CLEAR_EXPORT';