import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Modal,
  Tooltip,
} from "antd";
import { FormOutlined, SearchOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import { history } from "../../utils/constants/History";

import {
  LAY_DANH_SACH_CHI_PHI_SAGA,
  LAY_DANH_SACH_NHOM_CHI_PHI_SAGA,
  SET_EDIT_CHI_PHI,
  XOA_CHI_PHI_SAGA,
} from "../../redux/types/ChiPhiType";

const { RangePicker } = DatePicker;
const { confirm } = Modal;

export default function DanhSachChiPhi(props) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_NHOM_CHI_PHI_SAGA });
  }, []);

  const { danhSachNhomChiPhi, danhSachChiPhi, totalCount } = useSelector(
    (state) => state.ChiPhiReducer
  );

  const [filters, setFilters] = useState({
    idNhomChiPhi: "",
    startDate: null,
    endDate: null,
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const handleSearch = () => {
    setPagination({ ...pagination, current: 1 }); // Reset về trang 1 khi tìm kiếm mới
    dispatch({
      type: LAY_DANH_SACH_CHI_PHI_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_CHI_PHI_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const cancel = (e) => {
    message.error("Hủy Xóa Chi Phí");
  };

  const columns = [
    {
      title: "STT",
      key: "index",
      render: (text, record, index) => {
        return (pagination.current - 1) * pagination.pageSize + index + 1;
      },
      width: 10,
    },
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
    },
    {
      title: "Tên Nhóm Chi Phí",
      dataIndex: "TenNhomChiPhi",
      key: "TenNhomChiPhi",
    },
    {
      title: "Nội Dung",
      dataIndex: "NoiDung",
      key: "NoiDung",
    },
    {
      title: "Số Lượng",
      dataIndex: "SoLuong",
      key: "SoLuong",
      render: (text) => text,
      align: "right",
    },
    {
      title: "Đơn Giá",
      dataIndex: "DonGia",
      key: "DonGia",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
    {
      title: "Thành Tiền",
      dataIndex: "ThanhTien",
      key: "ThanhTien",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
    {
      title: "Ghi Chú",
      dataIndex: "GhiChu",
      key: "GhiChu",
    },
    {
      title: "Thông tin Tạo",
      dataIndex: "Ngay",
      key: "Ngay",
      render: (text, record) => {
        return (
          <>
            <div>
              Ngày tạo:{" "}
              <span className="fw-bold">
                {dayjs(text).format("DD/MM/YYYY")}
              </span>
            </div>
            <div>
              Người tạo: <span className="fw-bold">{record?.TenNguoiNhap}</span>
            </div>
            <div>
              mã NV:
              <span className="fw-bold">{record?.MaNhanVienNguoiNhap}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Chức năng",
      key: "Select",
      render: (text, record) => {
        return (
          <>
            <>
              <div>
                <button
                  className="btn me-2 btn-primary"
                  onClick={() => {
                    dispatch({
                      type: SET_EDIT_CHI_PHI,
                      chiPhiEdit: record,
                    });
                    history.push("/chi-phi/chinh-sua-chi-phi");
                  }}
                >
                  <Tooltip title="Chỉnh sửa Chi phí">
                    <FormOutlined style={{ fontSize: 17 }} />
                  </Tooltip>
                </button>
                <Popconfirm
                  title="Xóa Chi Phí"
                  description={`Bạn có chắc chắn muốn xóa Chi Phí "${record?.Id}"?`}
                  onConfirm={() => {
                    dispatch({
                      type: XOA_CHI_PHI_SAGA,
                      id: record?.Id,
                    });
                  }}
                  onCancel={cancel}
                  okText="Có"
                  cancelText="Không"
                >
                  <button className="btn btn-danger ml-2">
                    <Tooltip title="Xóa Chi phí">
                      <i className="fa-solid fa-trash"></i>
                    </Tooltip>
                  </button>
                </Popconfirm>
              </div>
            </>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="container row">
        <h3 className="col-md-9">Danh Sách Chi Phí</h3>
        <button
          className="btn btn-info col-md-3"
          onClick={() => {
            props?.history?.push("/chi-phi/them-moi-chi-phi");
          }}
        >
          <i className="fa-solid fa-plus"></i> Thêm Chi Phí
        </button>
      </div>

      <div className="search-filters my-4 row">
        <div className="col-md-4 ">
          <h6>Lọc theo Ngày Tạo</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            onChange={(dates) => {
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
                endDate:
                  dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>

        <div className="col-md-4">
          <h6>Chọn Nhóm Chi Phí</h6>

          <Select
            style={{ width: "100%" }}
            placeholder="Chọn Nhóm Chi Phí"
            value={filters.idNhomChiPhi}
            onChange={(value) =>
              setFilters({ ...filters, idNhomChiPhi: value })
            }
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachNhomChiPhi?.map((item, key) => {
              return (
                <Select.Option value={item?.Id} key={key}>
                  {item?.Ten}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="col-md-2 align-self-end">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            Tìm kiếm
          </Button>
        </div>
      </div>

      <h5 className="col-9">Danh Sách Order</h5>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachChiPhi}
        pagination={{
          ...pagination,
          total: totalCount,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
