import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  message,
  Popconfirm,
  Checkbox,
  Input,
  InputNumber,
} from "antd";
import { debounce } from "lodash";
import {
  FormOutlined,
  DeleteOutlined,
  LockOutlined,
  UnlockOutlined,
  UserAddOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import { history } from "../../utils/constants/History";
import {
  LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA,
  LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA,
} from "../../redux/types/NhanDonType";

const { RangePicker } = DatePicker;

export default function DanhSachOrderNhanDon() {
  const dispatch = useDispatch();

  const { totalCountOrder, danhSachTinhTrangOrder, danhSachOrder } =
    useSelector((state) => state.NhanDonReducer);

  const { danhSachNguonHang } = useSelector((state) => state?.NhanDonReducer);

  const [filters, setFilters] = useState({
    maOrder: "",
    trackingNumber: "",
    idNguonHang: "",
  });

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  useEffect(() => {
    dispatch({ type: LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA });
  }, [dispatch]);

  const handleSearch = () => {
    setPagination({ ...pagination, current: 1 }); // Reset về trang 1 khi tìm kiếm mới
    dispatch({
      type: LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  // const columns = [
  //   {
  //     title: "Mã",
  //     dataIndex: "Id",
  //     key: "Id",
  //   },
  //   {
  //     title: "Nguồn Hàng",
  //     dataIndex: "TenNguonHang",
  //     key: "TenNguonHang",
  //   },
  //   {
  //     title: "Mã Order",
  //     dataIndex: "MaOrder",
  //     key: "MaOrder",
  //   },
  //   {
  //     title: "Tracking number",
  //     dataIndex: "TrackingNumber",
  //     key: "TrackingNumber",
  //   },
  //   {
  //     title: "Số Đơn",
  //     dataIndex: "SoDon",
  //     key: "SoDon",
  //   },
  //   {
  //     title: "Số SP",
  //     dataIndex: "SoSanPham",
  //     key: "SoSanPham",
  //   },
  //   {
  //     title: "Số Tiền",
  //     dataIndex: "SoTien",
  //     key: "SoTien",
  //     render: (text) =>
  //       text
  //         ?.toLocaleString("vi-VN", {
  //           style: "currency",
  //           currency: "VND",
  //           minimumFractionDigits: 0,
  //           maximumFractionDigits: 0,
  //           useGrouping: true,
  //         })
  //         .replace(/\./g, ","),
  //     align: "right",
  //   },
  //   {
  //     title: "ShipWeb",
  //     dataIndex: "ShipWeb",
  //     key: "ShipWeb",
  //     render: (text) =>
  //       text
  //         ?.toLocaleString("vi-VN", {
  //           style: "currency",
  //           currency: "VND",
  //           minimumFractionDigits: 0,
  //           maximumFractionDigits: 0,
  //           useGrouping: true,
  //         })
  //         .replace(/\./g, ","),
  //     align: "right",
  //   },
  //   {
  //     title: "Ngày Nhận Hàng DK",
  //     dataIndex: "NgayNhanDonHangDuKien",
  //     key: "NgayNhanDonHangDuKien",
  //     render: (text) => dayjs(text).format("DD/MM/YYYY"),
  //   },
  //   {
  //     title: "Tình Trạng Order",
  //     dataIndex: "TenTinhTrangOrder",
  //     key: "TenTinhTrangOrder",
  //   },
  //   {
  //     title: "Thông tin order",
  //     dataIndex: "Ngay",
  //     key: "Ngay",
  //     render: (text, record) => {
  //       return (
  //         <>
  //           <div>
  //             Ngày tạo:{" "}
  //             <span className="fw-bold">
  //               {dayjs(text).format("DD/MM/YYYY")}
  //             </span>
  //           </div>
  //           <div>
  //             Người tạo:{" "}
  //             <span className="fw-bold">{record?.TenNhanVienOrder}</span>
  //           </div>
  //           <div>
  //             mã NV:<span className="fw-bold">{record?.MaNhanVienOrder}</span>
  //           </div>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Thông Tin Nhận Hàng",
  //     dataIndex: "NgayNhanHang",
  //     key: "NgayNhanHang",
  //     render: (text, record) => {
  //       const ngayNhanHang = text
  //         ? dayjs(text).format("DD/MM/YYYY")
  //         : "Chưa cập nhật";
  //       const nguoiNhan = record?.TenNhanVienNhanHang
  //         ? record?.TenNhanVienNhanHang
  //         : "Chưa cập nhật";
  //       const maNv = record?.MaNhanVienNhanHang
  //         ? record?.MaNhanVienNhanHang
  //         : "Chưa cập nhật";
  //       return (
  //         <>
  //           <div>
  //             Ngày Nhận ĐH: <span className="fw-bold">{ngayNhanHang}</span>
  //           </div>
  //           <div>
  //             Người Nhận: <span className="fw-bold">{nguoiNhan}</span>
  //           </div>
  //           <div>
  //             mã NV:
  //             <span className="fw-bold">{maNv}</span>
  //           </div>
  //         </>
  //       );
  //     },
  //   },
  //   {
  //     title: "Action",
  //     key: "Select",
  //     render: (text, record) => {
  //       return (
  //         <>
  //           <div>
  //             <button
  //               className="btn me-2 btn-primary"
  //               onClick={() => {
  //                 history.push(
  //                   `/nhan-don/chinh-sua-order-nhan-don/${record?.Id}`
  //                 );
  //               }}
  //             >
  //               <FormOutlined style={{ fontSize: 17 }} />
  //             </button>
  //           </div>
  //         </>
  //       );
  //     },
  //   },
  // ];
  const columns = [
    {
      title: "Mã",
      dataIndex: "Id",
      key: "Id",
    },
    // {
    //   title: "Nguồn Hàng",
    //   dataIndex: "TenNguonHang",
    //   key: "TenNguonHang",
    // },
    {
      title: "Mã order | Tracking",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              Order: <span className="fw-bold">{record?.MaOrder}</span>
            </div>
            <div>
              Tracking:{" "}
              <span className="fw-bold">{record?.TrackingNumber}</span>
            </div>
          </>
        );
      },
    },

    {
      title: "Số Lượng",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              SĐ: <span className="fw-bold">{record?.SoDon}</span>
            </div>
            <div>
              SP: <span className="fw-bold">{record?.SoSanPham}</span>
            </div>
          </>
        );
      },
    },
    {
      title: "Tiền",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              Số Tiền:{" "}
              <span className="fw-bold">
                {record?.SoTien?.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                }).replace(/\./g, ",")}
              </span>
            </div>
            <div>
              Ship Web:{" "}
              <span className="fw-bold">
                {record?.ShipWeb?.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                  useGrouping: true,
                }).replace(/\./g, ",")}
              </span>
            </div>
          </>
        );
      },
    },
    // {
    //   title: "Số Tiền",
    //   dataIndex: "SoTien",
    //   key: "SoTien",
    //   render: (text) =>
    //     text
    //       ?.toLocaleString("vi-VN", {
    //         style: "currency",
    //         currency: "VND",
    //         minimumFractionDigits: 0,
    //         maximumFractionDigits: 0,
    //         useGrouping: true,
    //       })
    //       .replace(/\./g, ","),
    //   align: "right",
    // },
    // {
    //   title: "ShipWeb",
    //   dataIndex: "ShipWeb",
    //   key: "ShipWeb",
    //   render: (text) =>
    //     text
    //       ?.toLocaleString("vi-VN", {
    //         style: "currency",
    //         currency: "VND",
    //         minimumFractionDigits: 0,
    //         maximumFractionDigits: 0,
    //         useGrouping: true,
    //       })
    //       .replace(/\./g, ","),
    //   align: "right",
    // },

    {
      title: "Ngày",
      dataIndex: "TenNguonHang",
      key: "TenNguonHang",
      render: (text, record) => {
        const NgayNhanHang = record?.NgayNhanHang
          ? dayjs(record?.NgayNhanHang).format("DD/MM/YYYY")
          : "";
        const NgayNhanDonHangDuKien = record?.NgayNhanDonHangDuKien
          ? dayjs(record?.NgayNhanDonHangDuKien).format("DD/MM/YYYY")
          : "";
        const ngayTao = record?.Ngay
          ? dayjs(record?.Ngay).format("DD/MM/YYYY")
          : "";
        return (
          <>
            <div>
              Tạo: <span className="fw-bold">{ngayTao}</span>
            </div>
            <div>
              Dự Diến: <span className="fw-bold">{NgayNhanDonHangDuKien}</span>
            </div>
            <div>
              Nhận: <span className="fw-bold">{NgayNhanHang}</span>
            </div>
          </>
        );
      },
    },

    {
      title: "Tình Trạng",
      dataIndex: "TenTinhTrangOrder",
      key: "TenTinhTrangOrder",
      render: (text, record) => {
        return (
          <>
            <div>{text}</div>
            <div className="fw-bold">{record?.TenNhanVienOrder}</div>
          </>
        );
      },
    },
    {
      title: "Chức Năng",
      key: "Select",
      render: (text, record) => {
        return (
          <>
            <div>
              <button
                className="btn me-2 btn-primary"
                onClick={() => {
                  history.push(
                    `/nhan-don/chinh-sua-order-nhan-don/${record?.Id}`
                  );
                }}
              >
                <FormOutlined style={{ fontSize: 17 }} />
              </button>
            </div>
          </>
        );
      },
    },
  ];
  return (
    <div>
      <h3 className="col-md-9">Nhận Đơn</h3>

      <div className="search-filters my-4 row">
        <div className="col-md-2">
          <h6>Mã Order</h6>
          <Input
            value={filters.maOrder}
            onChange={(e) =>
              setFilters({ ...filters, maOrder: e.target.value })
            }
            placeholder="Nhập mã order"
          />
        </div>

        <div className="col-md-2">
          <h6>Tracking Number</h6>
          <Input
            value={filters.trackingNumber}
            onChange={(e) =>
              setFilters({ ...filters, trackingNumber: e.target.value })
            }
            placeholder="Nhập tracking number"
          />
        </div>

        <div className="col-md-2">
          <h6>Chọn Nguồn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            value={filters.idNguonHang}
            onChange={(value) => setFilters({ ...filters, idNguonHang: value })}
            placeholder="Chọn nguồn hàng"
          >
            <Select.Option value="">Tất cả</Select.Option>
            {danhSachNguonHang?.map((item, key) => (
              <Select.Option key={key} value={item?.Id}>
                {item?.Ten}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="col-md-2 align-self-end">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            onClick={handleSearch}
          >
            Tìm kiếm
          </Button>
        </div>
      </div>

      <h5 className="col-md-9">Danh Sách Order</h5>

      <Table
        columns={columns}
        rowKey={"Id"}
        dataSource={danhSachOrder}
        pagination={{
          ...pagination,
          total: totalCountOrder,
        }}
        onChange={handleTableChange}
      />
    </div>
  );
}
