import React, { useState } from "react";
import { DatePicker, Button, Table, Form, message } from "antd";
import { thongKeBaoCaoService } from "../../services/thongKeBaoCaoService";
import { Notification } from "../../utils/Notifications/Notification";
import { NOTIFICATION_TYPE } from "../../utils/settings/config";

const ThongKeChiPhi = () => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [data, setData] = useState([]);

  const handleThongKe = async () => {
    if (!startDate || !endDate) {
      Notification(NOTIFICATION_TYPE.error, "Vui Lòng Chọn Thời Gian");
      return;
    }

    try {
      const { data, status } =
        await thongKeBaoCaoService.layThongKeNhomChiPhiAPI(startDate, endDate);
      setData(data?.data?.data);
    } catch (error) {
      Notification(
        NOTIFICATION_TYPE.error,
        "Không thể lấy dữ liệu",
        error?.response?.data?.message
      );
    }
  };

  const columns = [
    {
      title: "STT",
      dataIndex: "index",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    { title: "Nhóm Chi Phí", dataIndex: "nhomChiPhi", key: "nhomChiPhi" },
    { title: "Số lượt", dataIndex: "soLuot", key: "soLuot", align: "right" },
    {
      title: "Tổng Tiền",
      dataIndex: "tongTien",
      key: "tongTien",
      render: (text) =>
        text
          ?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
            useGrouping: true,
          })
          .replace(/\./g, ","),
      align: "right",
    },
  ];

  return (
    <div>
      <h2>Thống Kê Chi Phí</h2>
      <Form layout="inline">
        <Form.Item label="Từ ngày">
          <DatePicker
            format={"DD/MM/YYYY"}
            onChange={(date) => setStartDate(date)}
          />
        </Form.Item>
        <Form.Item label="Tới ngày">
          <DatePicker
            format={"DD/MM/YYYY"}
            onChange={(date) => setEndDate(date)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={handleThongKe}>
            Thống kê
          </Button>
        </Form.Item>
      </Form>

      <Table dataSource={data} columns={columns} rowKey="nhanVien" />
    </div>
  );
};

export default ThongKeChiPhi;
