import React from "react";

const NhanDonPrint = ({ donHang }) => {
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div className="row align-items-center" style={{ padding: "20px 0" }}>
        <div className="col-4 d-flex justify-content-center">
          <img
            src={require("../assets/imageLogo/logo.jpg")}
            alt="Logo"
            style={{ width: "200px" }}
          />
        </div>
        <div className="col-8 text-center">
          <h1 style={{ fontWeight: "bold", color: "#333" }}>Mẹ Gấu Order</h1>
          <h2 style={{ fontWeight: "bold", color: "#333" }}>Authentic</h2>
          <p style={{ fontWeight: "bold", fontSize: "18px", color: "#333" }}>
            0944.089.919
          </p>
        </div>
      </div>

      <div className="mt-3 ms-4">
        <p>
          <strong>Tên Sản Phẩm:</strong> {donHang?.TenSanPham}
        </p>
        <p>
          <strong>Tên khách hàng:</strong> {donHang?.TenKhachHang}
        </p>

        <p>
          <strong>Số điện thoại:</strong> {donHang?.SoDienThoai}
        </p>
        <p>
          <strong>Giá Tiền:</strong>{" "}
          {(donHang?.GiaBaoVoiKhach + donHang?.TongTienCan)?.toLocaleString(
            "vi-VN",
            {
              style: "currency",
              currency: "VND",
              minimumFractionDigits: 0,
            }
          )}
        </p>
      </div>

      <p style={{ textAlign: "center", marginTop: "40px" }}>
        Xín chân thành cảm ơn quý khách ...!!!
      </p>
    </div>
  );
};

export default NhanDonPrint;
