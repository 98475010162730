export const THEM_DON_HANG_DA_CHON = "THEM_DON_HANG_DA_CHON";
export const XOA_DON_HANG_DA_CHON = "XOA_DON_HANG_DA_CHON";
export const XOA_HET_DON_HANG_DA_CHON = "XOA_HET_DON_HANG_DA_CHON";
export const SET_ORDER_EDIT = 'SET_ORDER_EDIT'
export const RESET_ORDER = 'RESET_ORDER'

export const SET_DANH_SACH_ORDER = 'SET_DANH_SACH_ORDER'

// saga

export const SET_DANH_SACH_DON_HANG_ORDER = 'SET_DANH_SACH_DON_HANG_ORDER'

export const SET_DANH_SACH_DON_HANG_ORDER_SAGA = 'SET_DANH_SACH_DON_HANG_ORDER_SAGA'

export const TAO_ORDER_SAGA = 'TAO_ORDER_SAGA'

export const LAY_DANH_SACH_ORDER_SAGA = 'LAY_DANH_SACH_ORDER_SAGA'
export const XOA_ORDER_SAGA = 'XOA_ORDER_SAGA'

export const LAY_CHI_TIET_ORDER_SAGA = 'LAY_CHI_TIET_ORDER_SAGA'
export const XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRONG_ORDER = 'XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRONG_ORDER'
export const CHINH_SUA_ORDER_SAGA = 'CHINH_SUA_ORDER_SAGA'

export const LAY_CHI_TIET_ORDER_SAGA_PUBLIC = 'LAY_CHI_TIET_ORDER_SAGA_PUBLIC'
