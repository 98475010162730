import { call, delay, fork, take, takeEvery, takeLatest, put, select } from 'redux-saga/effects';
import { CHINH_SUA_ORDER_SAGA, CHINH_SUA_TRANG_THAI_DON_HANG_TRANG_TAO_ORDER_SAGA, LAY_CHI_TIET_ORDER_SAGA, LAY_CHI_TIET_ORDER_SAGA_PUBLIC, LAY_DANH_SACH_ORDER_SAGA, SET_DANH_SACH_DON_HANG_ORDER, SET_DANH_SACH_DON_HANG_ORDER_SAGA, SET_DANH_SACH_ORDER, SET_ORDER_EDIT, TAO_ORDER_SAGA, XOA_DON_HANG_DA_CHON, XOA_HET_DON_HANG_DA_CHON, XOA_ORDER_SAGA, XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRONG_ORDER } from '../types/OderType';
import { DISPLAY_LOADING, HIDE_LOADING } from '../types/LoadingType';
import { Notification } from '../../utils/Notifications/Notification';
import { NOTIFICATION_TYPE, STATUS_CODE } from '../../utils/settings/config';
import { orderService } from '../../services/orderService';
import { history } from '../../utils/constants/History';

function* layDanhSachDonHangSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return orderService.layDanhSachDonHangPhanTrangAPI(page, pageSize, filters?.label, filters?.orderStatus, filters?.startDate, filters?.endDate);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_DON_HANG_ORDER,
                danhSachDonHang: data?.data?.data,
                totalCount: data?.data?.totalCount,
                page: data?.data?.page,
                pageSize: data?.data?.pageSize
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách Đơn Hàng thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhSachDonHangSaga() {
    yield takeLatest(SET_DANH_SACH_DON_HANG_ORDER_SAGA, layDanhSachDonHangSaga)
}

function* taoOrderSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { orderInfo } = action;
        const { data, status } = yield call(() => {
            return orderService.taoOrderAPI(action?.idNguonHang, orderInfo?.maOrder, orderInfo?.trackingNumber, orderInfo?.soTien, orderInfo?.shipWeb, orderInfo?.ngayNhanHangDuKien, orderInfo?.tiGia, action?.danhSachDonHang);
        });


        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: XOA_HET_DON_HANG_DA_CHON
            })
            history.push('/order')
            Notification(NOTIFICATION_TYPE.success, "Tạo Order Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Tạo Order Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiTaoOrderSaga() {
    yield takeLatest(TAO_ORDER_SAGA, taoOrderSaga)
}

function* layDanhOrderSaga(action) {
    const { page, pageSize, filters } = action;
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return orderService.layDanhSachOrderKeywordPhanTrangAPI(page, pageSize, filters?.label, filters?.orderStatus, filters?.startDate, filters?.endDate, filters?.maOrder);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_ORDER,
                danhSachOrder: data?.data?.data,
                totalCountOrder: data?.data?.totalCount,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy danh sách order thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayDanhOrderSaga() {
    yield takeLatest(LAY_DANH_SACH_ORDER_SAGA, layDanhOrderSaga)
}

function* xoaOrderSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return orderService.xoaOrderAPI(action?.id);
        })


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: LAY_DANH_SACH_ORDER_SAGA,
            })
            Notification(NOTIFICATION_TYPE.success, "Xóa order thành công!");
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Xóa order thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoXoaOrderSaga() {
    yield takeLatest(XOA_ORDER_SAGA, xoaOrderSaga)
}

function* layChiTietOrderSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return orderService.layOrderChiTietAPI(action?.id);
        })



        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_ORDER_EDIT,
                danhSachDonHangDaChon: data?.data?.danhSachDonHangDaChon,
                orderEdit: data?.data?.orderChiTiet,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Thông tin chi tiết Order Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayChiTietOrderSaga() {
    yield takeLatest(LAY_CHI_TIET_ORDER_SAGA, layChiTietOrderSaga)
}

function* xoaThayDoiDonHangOrderSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {
        const { data, status } = yield call(() => {
            return orderService.xoaThayDoiTrangThaiDonHangOrderAPI(action?.idOrder, action?.idDonHang, action?.idTrangThai);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({ type: XOA_DON_HANG_DA_CHON, payload: action?.record });

            Notification(NOTIFICATION_TYPE.success, "xóa đơn hàng trong order và thay đổi Trạng Thái Thành công!")
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "xóa đơn hàng trong order và thay đổi Trạng Thái Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoXoaThayDoiDonHangOrderSaga() {
    yield takeLatest(XOA_THAY_DOI_TRANG_THAI_DON_HANG_TRONG_ORDER, xoaThayDoiDonHangOrderSaga)
}

function* chinhSuaOrderSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { orderInfo } = action;
        const { data, status } = yield call(() => {
            return orderService.chinhSuaOrderAPI(action?.idNguonHang, orderInfo?.maOrder, orderInfo?.trackingNumber, orderInfo?.soTien, orderInfo?.shipWeb, orderInfo?.ngayNhanHangDuKien, orderInfo?.tiGia, action?.danhSachDonHang, action?.idOrder);
        });


        if (status == STATUS_CODE.CREATED) {
            yield put({
                type: XOA_HET_DON_HANG_DA_CHON
            })
            history.push('/order')
            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Order Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Order Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaOrderSaga() {
    yield takeLatest(CHINH_SUA_ORDER_SAGA, chinhSuaOrderSaga)
}

function* layChiTietOrderPublicSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })

    try {

        const { data, status } = yield call(() => {
            return orderService.layOrderChiTietPublicAPI(action?.id);
        })

        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_ORDER_EDIT,
                danhSachDonHangDaChon: data?.data?.danhSachDonHangDaChon,
            })
        }

    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Lấy Thông tin chi tiết Order Thất bại", error?.response?.data?.message);
    }

    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiLayChiTietOrderPublicSaga() {
    yield takeLatest(LAY_CHI_TIET_ORDER_SAGA_PUBLIC, layChiTietOrderPublicSaga)
}


function* chinhSuaTrangThaiDonHangSaga(action) {
    yield put({
        type: DISPLAY_LOADING
    })
    try {
        const { data, status } = yield call(() => {
            return orderService.chinhSuaTinhTrangDonHangAPI(action?.idDonHang, action?.idTrangThai);
        });


        if (status == STATUS_CODE.SUCCESS) {
            yield put({
                type: SET_DANH_SACH_DON_HANG_ORDER_SAGA,
                page: action?.page,
                pageSize: action?.pageSize,
                filters: action?.filters,
            })

            Notification(NOTIFICATION_TYPE.success, "Chỉnh Sửa Tình Trạng Đơn Hàng Thành Công!")
        }
    } catch (error) {
        Notification(NOTIFICATION_TYPE.error, "Chỉnh Sửa Tình Trạng Đơn Hàng Thất Bại", error?.response?.data?.message);
    }
    yield put({
        type: HIDE_LOADING
    })
}

export function* theoDoiChinhSuaTrangThaiDonHangSaga() {
    yield takeLatest(CHINH_SUA_TRANG_THAI_DON_HANG_TRANG_TAO_ORDER_SAGA, chinhSuaTrangThaiDonHangSaga)
}