import logo from '../../assets/imageLogo/logo.jpg'
import './HomeTemplate.css'
import { useState, useEffect } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import {
    DesktopOutlined,
    FileOutlined,
    PieChartOutlined,
    TeamOutlined,
    UserOutlined,
    OrderedListOutlined,
    PlusOutlined,
    LineChartOutlined,
    DollarOutlined,
    ShoppingCartOutlined,
    InboxOutlined,
    DeliveredProcedureOutlined,
    FormOutlined,
    DownOutlined,
    StockOutlined,
    LogoutOutlined,
    RightOutlined,
    LeftOutlined
} from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme, Dropdown, Button, Space } from 'antd';
import FooterLayout from "./Layout/Footer/Footer";
import { USER_INFO } from "../../utils/settings/config";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/actions/LoginAction";
const { Content, Sider, Header } = Layout;

function getItem(label, key, icon, path, children) {
    return {
        key,
        icon,
        label,
        path,
        children,
    };
}

const permissionsMapping = {
    khach_hang: { label: 'Khách hàng', icon: <TeamOutlined />, path: '/khach-hang' },
    don_hang: {
        label: 'Chốt Đơn',
        icon: <ShoppingCartOutlined />,
        path: '/don-hang',
    },
    order: {
        label: 'Mua Hàng',
        icon: <FormOutlined />,
        path: '/order',
    },
    nhan_don: { label: 'Soạn Hàng', icon: <InboxOutlined />, path: '/nhan-don' },
    giao_hang: {
        label: 'Giao hàng',
        icon: <DeliveredProcedureOutlined />,
        path: '/giao-hang',
    },
    ket_thuc: { label: 'Kết thúc', icon: <PieChartOutlined />, path: '/ket-thuc' },
    chi_phi: {
        label: 'Chi phí',
        icon: <DollarOutlined />,
        path: '/chi-phi',
    },
    quan_tri: {
        label: 'Quản trị',
        icon: <UserOutlined />,
        path: '/quan-tri',
        children: [
            { label: 'Quản Lý Người Dùng', key: 'quan_ly_nguoi_dung', icon: <OrderedListOutlined />, path: '/quan-tri/quan-ly-nguoi-dung' },
            { label: 'Quản Lý Nguồn Hàng', key: 'quan_ly_nguon_hang', icon: <DesktopOutlined />, path: '/quan-tri/quan-ly-nguon-hang' },
            { label: 'Thay Đổi Tỷ Giá', key: 'thay_doi_ty_gia', icon: <DollarOutlined />, path: '/quan-tri/thay-doi-ty-gia' },
        ],
    },
    thong_ke_bao_cao: {
        label: 'Thống kê báo cáo',
        icon: <LineChartOutlined />,
        path: '/thong-ke-bao-cao',
        children: [
            { label: 'Thống Kê Doanh Thu, Chi Phí', key: 'thong_ke_doanh_thu_chi_phi', icon: <StockOutlined />, path: '/thong-ke-bao-cao/thong-ke-doanh-thu-chi-phi' },
            { label: 'Doanh Thu Theo Nhân Viên', key: 'doanh_thu_theo_nhan_vien', icon: <UserOutlined />, path: '/thong-ke-bao-cao/doanh-thu-theo-nhan-vien' },
            { label: 'Thống Kê Chi Phí', key: 'thong_ke_chi_phi', icon: <DollarOutlined />, path: '/thong-ke-bao-cao/thong-ke-chi-phi' },
            { label: 'Thống Kê Khách Hàng', key: 'thong_ke_khach_hang', icon: <TeamOutlined />, path: '/thong-ke-bao-cao/thong-ke-khach-hang' },
        ]
    },
};

const combinePermissions = (roles) => {
    const combinedPermissions = {};
    roles.forEach(role => {
        for (const permission in role) {
            if (!combinedPermissions[permission]) {
                combinedPermissions[permission] = { ...role[permission] };
            } else {
                for (const action in role[permission]) {
                    combinedPermissions[permission][action] = combinedPermissions[permission][action] || role[permission][action];
                }
            }
        }
    });
    return combinedPermissions;
};

const generateMenuItems = (permissions) => {
    const items = [];
    for (const permission in permissions) {
        // Skip 'ql_nguon_hang' and 'tien_te'
        if (permission === 'ql_nguon_hang' || permission === 'tien_te') continue;

        if (permissions[permission].read) {
            const { label, icon, path, children } = permissionsMapping[permission];
            if (children) {
                const subItems = children.map(child => getItem(child.label, child.key, child.icon, child.path));
                items.push(getItem(label, permission, icon, path, subItems));
            } else {
                items.push(getItem(label, permission, icon, path));
            }
        }
    }

    // Sort the items to maintain the desired order
    const order = [
        'don_hang',
        'order',
        'nhan_don',
        'giao_hang',
        'ket_thuc',
        'khach_hang',
        'chi_phi',
        'quan_tri',
        'thong_ke_bao_cao',

    ];

    return items.sort((a, b) => order.indexOf(a.key) - order.indexOf(b.key));
};

const getPermissionsFromLocalStorage = () => {
    const userInfo = localStorage.getItem(USER_INFO);
    return userInfo ? JSON.parse(userInfo)?.permission : null;
};

const getUserInfoFromLocalStorage = () => {
    const userInfo = localStorage.getItem(USER_INFO);
    return userInfo ? JSON.parse(userInfo) : null;
};

export const HomeTemplate = (props) => {
    const [collapsed, setCollapsed] = useState(true);
    const [menuItems, setMenuItems] = useState([]);
    const [selectedKey, setSelectedKey] = useState([]);
    const dispatch = useDispatch();

    const {
        token: { colorBgContainer, borderRadiusLG },
    } = theme.useToken();
    const { Component, ...restProps } = props;
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        const roles = getPermissionsFromLocalStorage();
        if (roles) {
            const combinedPermissions = combinePermissions(roles);
            const items = generateMenuItems(combinedPermissions);
            setMenuItems(items);
        }
    }, []);

    useEffect(() => {
        const findSelectedKey = (items, pathname) => {
            for (const item of items) {
                if (item.path === pathname) return item.key;
                if (item.children) {
                    const childKey = findSelectedKey(item.children, pathname);
                    if (childKey) return childKey;
                }
            }
            return null;
        };

        const selectedKey = findSelectedKey(menuItems, location.pathname);
        if (selectedKey) {
            setSelectedKey(selectedKey);
        }
    }, [location.pathname, menuItems]);

    const handleMenuClick = ({ key }) => {
        const findPath = (items) => {
            for (const item of items) {
                if (item.key === key) return item.path;
                if (item.children) {
                    const childPath = findPath(item.children);
                    if (childPath) return childPath;
                }
            }
            return null;
        };

        const path = findPath(menuItems);
        if (path) {
            history.push(path);
        }
    };

    const userInfo = getUserInfoFromLocalStorage();

    const handleLogout = async () => {
        dispatch(logoutAction());
    };

    const items = [
        {
            label: <span onClick={handleLogout}>Đăng xuất <LogoutOutlined /></span>,
            key: '0',
        }
    ];

    return (
        <Route {...restProps} render={(propsRoute) => (
            <Layout style={{ minHeight: '100vh', background: '#FFC0CB' }}>
                <Sider
                    style={{ background: '#FFB6C1' }} // Lighter Pink for Sider
                    collapsible
                    collapsed={collapsed}
                    onCollapse={(value) => setCollapsed(value)}
                    trigger={null} // Remove default trigger to customize the collapse button
                >
                    <div style={{ textAlign: 'center', padding: '10px 0' }}>
                        <img src={logo} alt="logo" style={{ width: '80%', marginBottom: '10px' }} />
                    </div>
                    <Menu
                        theme="light" // Light theme for menu
                        selectedKeys={[selectedKey]}
                        mode="inline"
                        items={menuItems}
                        onClick={handleMenuClick}
                        style={{
                            background: '#FFB6C1', // Lighter Pink for Menu
                            color: '#000', // Black text for better contrast
                        }}
                        itemStyle={{
                            backgroundColor: '#FFF', // Default background for non-selected items
                            color: '#000', // Default text color
                        }}
                        selectedItemStyle={{
                            backgroundColor: '#FF69B4', // Darker Pink for selected item
                            color: '#FFF', // White text for selected item
                        }}
                    />
                    <Button
                        type="primary"
                        onClick={() => setCollapsed(!collapsed)}
                        style={{
                            width: '100%',
                            borderRadius: 0,
                            background: '#FF69B4', // Darker pink for collapse button
                            borderColor: '#FF69B4', // Match border color
                            color: '#FFF' // White text for collapse button
                        }}
                    >
                        {collapsed ? <RightOutlined /> : <LeftOutlined />}
                    </Button>
                </Sider>




                <Layout>
                    <Header style={{ padding: 0, background: colorBgContainer, display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 24 }}>
                        <div className="logo" />
                        {userInfo && (
                            <Dropdown
                                menu={{
                                    items,
                                }}
                                trigger={['click']}
                            >
                                <a onClick={(e) => e.preventDefault()}>
                                    <Button>
                                        {userInfo.tenNhanVien}
                                        <DownOutlined />
                                    </Button>
                                </a>
                            </Dropdown>
                        )}
                    </Header>
                    <Content style={{ margin: '0 16px' }}>
                        <Breadcrumb style={{ margin: '16px 0' }} />
                        <div
                            style={{
                                padding: 24,
                                minHeight: 360,
                                background: colorBgContainer,
                                borderRadius: borderRadiusLG,
                            }}
                        >
                            <Component {...propsRoute} />
                        </div>
                    </Content>
                    <FooterLayout />
                </Layout>
            </Layout>
        )} />
    );
};
