import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Popconfirm, Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { useReactToPrint } from "react-to-print";
import {
  LAY_DANH_SACH_CHON_NHAN_DON_SAGA,
  SET_DON_HANG_EDIT_NHAN_DON,
  XAC_NHAN_DA_NHAN_HANG_SAGA,
} from "../../redux/types/NhanDonType";
import { SET_CONTENT_DRAWER } from "../../redux/types/GlobalType";
import FormChinhSuaDonHangNhanDon from "../../components/Forms/NhanDon/FormChinhSuaDonHangNhanDon";
import NhanDonPrint from "../../components/NhanDonPrint";

export default function ChinhSuaOrderNhanDon(props) {
  const dispatch = useDispatch();

  const { danhSachDonHangDaChon, orderEdit } = useSelector(
    (state) => state.NhanDonReducer
  );

  const printRef = useRef();
  const [isPrinting, setIsPrinting] = useState(false);
  const [recordCurrent, setRecorCurrent] = useState({ record: {} });

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  useEffect(() => {
    if (isPrinting) {
      handlePrint();
      setIsPrinting(false); // Reset after printing
    }
  }, [isPrinting]);

  const handlePrintClick = (record) => {
    setRecorCurrent(record); // Set the specific record for printing
    setIsPrinting(true); // Trigger the printing effect
  };

  useEffect(() => {
    dispatch({
      type: LAY_DANH_SACH_CHON_NHAN_DON_SAGA,
      id: props?.match?.params?.id,
    });
  }, []);

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const formatInteger = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDecimal = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const selectedOrderColumns = [
    {
      title: "Mã",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
      render: (text, record) => {
        return (
          <>
            <div>{record?.MaDonHang}</div>
            <div>{record?.TenNguonHang}</div>
          </>
        );
      },
    },

    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
            <div
              style={{
                width: "90px",
                height: "90px",
                border: "1px solid #d9d9d9",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
              }}
            >
              <img
                src={record?.LinkAnh}
                alt="Product"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </div>
          </a>
        </div>
      ),
    },
    {
      title: "Sản phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div>
            <div>
              <div>
                <span style={{ fontWeight: "bold" }}>{record?.TenSanPham}</span>
                {" | "}
                <span style={{ fontWeight: "bold" }}>
                  {record?.MauSanPham}
                </span>{" "}
                {" | "}
                <span style={{ fontWeight: "bold" }}>{record?.Size}</span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
                {" gam | SL: "}
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>{" "}
                {record?.tbNguonHang?.tbTienTe?.TienTe} | CodeOff:{" "}
                <span style={{ fontWeight: "bold" }}>{record?.CodeOff}%</span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div>
              Giá:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div>
              Cọc:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div>
              Cân:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Khách Hàng",
      key: "LinkFacebook",
      with: "20%",
      render: (text, record) => (
        <div>
          <div>
            {record.LinkFacebook ? (
              <a target="_blank" href={record?.LinkFacebook}>
                <span style={{ fontWeight: "bold", color: "inherit" }}>
                  {record?.TenKhachHang}
                </span>
              </a>
            ) : (
              <span style={{ fontWeight: "bold", color: "inherit" }}>
                {record?.TenKhachHang}
              </span>
            )}

            {" ("}
            <span style={{ fontWeight: "bold", color: "inherit" }}>
              {record?.SoDienThoai}
            </span>
            {") "}
          </div>
          <div style={{ fontWeight: "bold", color: "inherit" }}>
            {record?.DiaChiNhanHang}
          </div>
        </div>
      ),
    },
    {
      title: "Tình Trạng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              {record?.IdTinhTrang === 3 ||
              record?.IdTinhTrang === 4 ||
              record?.IdTinhTrang === 8 ||
              record?.IdTinhTrang === 9 ? (
                <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
              ) : (
                <span>{text}</span>
              )}
            </div>
            <div>
              Ngày Lên:
              <span className="fw-bold">
                {dayjs(record?.NgayTao).format("DD/MM/YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Hành Động",
      key: "action",
      render: (text, record) => {
        return (
          <div className="row">
            <button
              className=" col-7 btn btn-primary me-2"
              type="link"
              onClick={() => {
                dispatch({
                  type: SET_DON_HANG_EDIT_NHAN_DON,
                  record: {
                    ...record,
                    idOrder: orderEdit?.Id,
                  },
                });

                dispatch({
                  type: SET_CONTENT_DRAWER,
                  Component: <FormChinhSuaDonHangNhanDon />,
                  title: "Chỉnh Sửa Trạng Thái Đơn Hàng",
                });
              }}
            >
              <i className="fa-solid fa-pen-to-square"></i> Chỉnh Sửa
            </button>

            <button
              className="col-4 btn btn-secondary"
              onClick={() => {
                // dispatch({
                //   type: LAY_CHI_TIET_DON_SHIP_SAGA,
                //   id: record?.Id,
                // });
                handlePrintClick(record);
              }}
            >
              <Tooltip title="In Tem">
                <i className="fa-solid fa-print"></i>
              </Tooltip>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h3 className="col-md-9">Nhận Đơn</h3>

      <h4>Danh Sách Đơn Hàng</h4>

      <Table
        columns={selectedOrderColumns}
        rowKey={"Id"}
        dataSource={danhSachDonHangDaChon}
        pagination={false} // Disable pagination for selected orders table
      />

      {orderEdit?.IdTinhTrang != 2 ? (
        <Button
          htmlType="submit"
          size="large"
          className="mt-3"
          style={{
            width: "100%",
            backgroundColor: "#1890ff",
            color: "white",
          }}
        >
          <Popconfirm
            title="Bạn có chắc chắn đã nhận hàng không?"
            onConfirm={() => {
              dispatch({
                type: XAC_NHAN_DA_NHAN_HANG_SAGA,
                idOrder: orderEdit?.Id,
              });
            }}
            okText="Xác nhận"
            cancelText="Hủy"
          >
            <span>Xác nhận đã Nhận Hàng</span>
          </Popconfirm>
        </Button>
      ) : (
        ""
      )}
      <div style={{ display: "none" }}>
        <div ref={printRef}>
          <NhanDonPrint donHang={recordCurrent} />
        </div>
      </div>
    </div>
  );
}
