import React, { useState, useEffect } from "react";
import { LAY_CHI_TIET_ORDER_SAGA_PUBLIC } from "../../redux/types/OderType";
import { Button, Table } from "antd";
import { useSelector, useDispatch } from "react-redux";

export default function ChiTietOrder(props) {
  const dispatch = useDispatch();

  const { danhSachDonHangDaChon } = useSelector((state) => state.OrderReducer);

  console.log(danhSachDonHangDaChon);

  useEffect(() => {
    dispatch({
      type: LAY_CHI_TIET_ORDER_SAGA_PUBLIC,
      id: props?.match?.params?.id,
    });
  }, []);

  const [showImage, setShowImage] = useState(true);
  const loaiTienTe = danhSachDonHangDaChon[0]?.tbNguonHang?.tbTienTe?.TienTe;
  let tongSoLuong = 0;
  let totalBefore = 0;
  let totalAfter = 0;
  for (let index = 0; index < danhSachDonHangDaChon.length; index++) {
    tongSoLuong += danhSachDonHangDaChon[index]?.SoLuong;
    totalBefore += danhSachDonHangDaChon[index]?.GiaWeb;
    totalAfter +=
      ((100 - danhSachDonHangDaChon[index]?.CodeOff) *
        danhSachDonHangDaChon[index]?.GiaWeb *
        1.0) /
      100;
  }

  totalBefore = totalBefore.toFixed(3);
  totalAfter = totalAfter.toFixed(3);

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const columns = [
    {
      title: "#",
      key: "stt",
      render: (text, record, index) => index + 1,
      align: "center",
    },
    {
      title: "Trạng Thái",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
    },

    {
      title: "Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          {showImage && (
            <a target="_blank" href={record?.LinkSanPham} rel="noreferrer">
              <div
                style={{
                  width: "100px",
                  height: "100px",
                  border: "1px solid #d9d9d9",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <img
                  src={record?.LinkAnh}
                  alt="Product"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </div>
            </a>
          )}
          <div style={{ fontSize: "14px", color: "#3e81bc" }}>
            {record?.TenSanPham}
          </div>
        </div>
      ),
    },
    {
      title: "Giá Tiền",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text) => {
        return <span>{formatCurrency(text)}</span>;
      },
      align: "right",
    },
    {
      title: "Giá web",
      dataIndex: "GiaWeb",
      key: "GiaWeb",
      align: "right",
    },
    {
      title: "Off Extra",
      dataIndex: "CodeOff",
      key: "CodeOff",
      render: (text) => <div>{text}%</div>,
      align: "right",
    },
    {
      title: "SL",
      dataIndex: "SoLuong",
      key: "SoLuong",
      align: "right",
    },
    {
      title: "Thông tin sản phẩm",
      dataIndex: "SoLuong",
      key: "SoLuong",
      render: (text, record) => {
        return (
          <>
            <div style={{ fontSize: 14 }}>
              <div>
                Size:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.Size}
                </span>
              </div>
              <div>
                Color:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.MauSanPham}
                </span>
              </div>
              <div>
                Mã DH:{" "}
                <span style={{ fontWeight: "bold", color: "#3498db" }}>
                  {record?.MaDonHang}
                </span>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="container my-5">
        <div className="row">
          <h3 className="col-md-9">
            Chi Tiết Order - {props?.match?.params?.id}
          </h3>
          <div className="col-md-3">
            <Button
              type="primary"
              onClick={() => setShowImage(!showImage)}
              style={{ marginBottom: "20px", backgroundColor: "#36c6d3" }}
            >
              {showImage ? "Ẩn Ảnh" : "Hiện Thị Ảnh"}
            </Button>
          </div>
        </div>
        <Table
          columns={columns}
          rowKey={"id"}
          dataSource={danhSachDonHangDaChon}
          pagination={false}
        />
      </div>
      <footer
        className="text-center"
        style={{
          height: "30px",
          width: "100%",
          position: "fixed",
          backgroundColor: "#26C281",
          fontSize: 20,
          lineHeight: "25px",
          color: "white",
          bottom: "0",
          zIndex: 1000,
        }}
      >
        Tổng số lượng: <span style={{ fontWeight: "bold" }}>{tongSoLuong}</span>{" "}
        - Trước off: <span style={{ fontWeight: "bold" }}>{totalBefore}</span>{" "}
        {loaiTienTe} - Sau off:{" "}
        <span style={{ fontWeight: "bold" }}>{totalAfter}</span> {loaiTienTe}
      </footer>
    </>
  );
}
