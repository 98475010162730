import {
  Input,
  Select,
  DatePicker,
  Upload,
  Button,
  Divider,
  InputNumber,
} from "antd";
import {
  InboxOutlined,
  LinkOutlined,
  DollarOutlined,
  EditOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { withFormik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { SET_CONTENT_DRAWER, SET_SUBMIT } from "../../redux/types/GlobalType";
import $ from "jquery"; // Ensure jQuery is installed
import { fileService } from "../../services/fileService";
import {
  LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA,
  SET_KHACH_HANG_DON_HANG,
  THEM_DON_HANG_SAGA,
} from "../../redux/types/DonHangType";
import { Modal } from "antd";
import { donHangService } from "../../services/donHangService";

import dayjs from "dayjs";
import ThemKhachHangDonHang from "../../components/Forms/DonHang/ThemKhachHangDonHang";

const { Option } = Select;
const DEFAULT_IMAGE = "https://via.placeholder.com/250"; // Đường dẫn hình ảnh mặc định

function FormAddOrder(props) {
  const { danhSachNguonHang } = useSelector((state) => state.NguonHangReducer);
  const { khachHang } = useSelector((state) => state.DonHangReducer);
  const dispatch = useDispatch();

  const [imgSrc, setImgSrc] = useState(DEFAULT_IMAGE); // Set hình ảnh mặc định
  const [spanContent, setSpanContent] = useState("");

  const { values, errors, handleChange, handleSubmit, setFieldValue } = props;

  useEffect(() => {
    dispatch({ type: SET_KHACH_HANG_DON_HANG });
    dispatch({ type: LAY_DANH_SACH_NGUON_HANG_TRANG_DON_HANG_SAGA });
  }, []);

  useEffect(() => {
    setFieldValue("soDienThoai", khachHang?.SoDienThoai);
    setFieldValue("tenKhachHang", khachHang?.TenKhachHang);
    setFieldValue("linkFacebook", khachHang?.LinkFaceBook);
    setFieldValue("diaChiNhanHang", khachHang?.DiaChiNhanHang);
    setFieldValue("idKhachHang", khachHang?.Id);
  }, [khachHang]);

  useEffect(() => {
    // Khi giá trị của linkAnh thay đổi, cập nhật hình ảnh
    if (values.linkAnh) {
      setImgSrc(values.linkAnh);
    } else {
      setImgSrc(DEFAULT_IMAGE); // Sử dụng hình ảnh mặc định nếu không có link
    }
  }, [values.linkAnh]);

  const handleFetchData = () => {
    const proxyUrl = "https://cors-anywhere.herokuapp.com/";
    const url = values.linkSanPham;

    $.ajax({
      url: proxyUrl + url,
      method: "GET",
      headers: {
        Origin: window.location.origin,
        "X-Requested-With": "XMLHttpRequest",
      },
      success: function (data) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(data, "text/html");

        const imgXPath =
          "/html/body/div[1]/div[2]/div[1]/div/div/div[1]/div/ul/li[1]/button/div/span/img";
        const spanXPath =
          "/html/body/div[1]/div[2]/div[1]/div/div/div[2]/div/div/div[2]/span[1]";

        const imgElement = doc.evaluate(
          imgXPath,
          doc,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;
        const spanElement = doc.evaluate(
          spanXPath,
          doc,
          null,
          XPathResult.FIRST_ORDERED_NODE_TYPE,
          null
        ).singleNodeValue;

        if (imgElement) {
          setImgSrc(imgElement.src);
          setFieldValue("linkAnh", imgElement.src);
        } else {
          setImgSrc(DEFAULT_IMAGE);
        }

        if (spanElement) {
          setSpanContent(spanElement.textContent);
          setFieldValue("giaWeb", spanElement.textContent);
        } else {
          setSpanContent("Không tìm thấy thẻ span với XPath đã cho.");
        }
      },
      error: function (xhr, status, error) {
        console.error("Error:", error);
      },
    });
  };

  const handleUpload = async (file) => {
    const formData = new FormData();
    formData.append("image", file);

    try {
      const data = await fileService.uploadImageAPI(formData);
      if (data.status === 200) {
        setFieldValue("linkAnh", data.data.filePath);
        setImgSrc(data.data.filePath);
      } else {
        console.error("Upload failed:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    return false; // Prevent upload
  };

  const handleFetchCustomerInfo = async () => {
    try {
      const { data, status } = await donHangService.layThongTinKhachHangAPI(
        values.soDienThoai
      );

      if (status === 200) {
        const customerInfo = data?.data.khachHangCanTim;
        setFieldValue("idKhachHang", customerInfo?.Id);
        setFieldValue("tenKhachHang", customerInfo?.TenKhachHang);
        setFieldValue("linkFacebook", customerInfo?.LinkFacebook);
        setFieldValue("diaChiNhanHang", customerInfo?.DiaChiNhanHang);
      }
    } catch (error) {
      if (error?.response?.data?.status == 404) {
        // If customer not found, show a popup
        Modal.confirm({
          title: "Thông tin khách hàng không tồn tại",
          content: "Bạn có muốn thêm mới khách hàng không?",
          onOk() {
            // Handle "OK" action, maybe redirect to add new customer form
            dispatch({
              type: SET_KHACH_HANG_DON_HANG,
              khachHang: {
                SoDienThoai: values?.soDienThoai,
              },
            });
            dispatch({
              type: SET_CONTENT_DRAWER,
              title: "Thêm Khách Hàng Mới",
              Component: <ThemKhachHangDonHang />,
            });
          },
          onCancel() {},
        });
      }

      console.error("Error fetching customer info:", error);
    }
  };

  const handleFetchReferencePrice = async () => {
    try {
      const { data, status } = await donHangService.layGiaTheoCanVaTiGiaAPI(
        values?.idNguonHang
      );

      if (status === 200) {
        const { TiGia, GiaTheoCan, TienCong } = data?.data;

        const giaBaoVoiKhach =
          values.giaWeb * ((100 - (values.codeOff || 0)) / 100) * TiGia;

        setFieldValue(
          "giaThamKhao",
          Math.round(
            giaBaoVoiKhach * values?.soLuong + TienCong * values?.soLuong
          )
        );
        setFieldValue("tiGiaHienTai", TiGia);
        setFieldValue("tienCong", TienCong);
      } else {
        console.error("Không lấy được tỉ giá và giá theo cân", data?.message);
      }
    } catch (error) {
      console.error("Không lấy được tỉ giá và giá theo cân", error);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="container-fluid">
      <h4 className="mt-4">Thêm Đơn Hàng</h4>

      <div className="row mt-3">
        <div className="col-md-6">
          <h6>
            Chọn Nguồn Hàng |{" "}
            <span style={{ color: "#3e81bc" }}>
              Tiền Công :{" "}
              {values?.tienCong?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
              })}
            </span>
          </h6>
          <Select
            value={values.idNguonHang}
            style={{ width: "100%" }}
            size="large"
            onChange={(value) => setFieldValue("idNguonHang", value)}
            placeholder="Chọn nguồn hàng"
          >
            {danhSachNguonHang?.map((item, key) => (
              <Option key={key} value={item?.Id}>
                {item?.Ten}
              </Option>
            ))}
          </Select>
          <div className="text-danger">{errors.idNguonHang}</div>

          <h6 className="mt-3">Link Sản Phẩm</h6>
          <div className="row">
            <Input
              name="linkSanPham"
              value={values.linkSanPham}
              size="large"
              placeholder="Link sản phẩm"
              prefix={<LinkOutlined />}
              onChange={handleChange}
              className="col-md-7"
            />
            <Button
              className="ms-md-2 col-md-4"
              type="primary"
              size="large"
              disabled
              style={{ backgroundColor: "#5a5a5a", color: "white" }}
              onClick={handleFetchData}
            >
              Lấy tự động
            </Button>
          </div>
          <div className="text-danger">{errors.linkSanPham}</div>

          <h6 className="mt-3">Hình Ảnh Sản phẩm</h6>
          <div
            style={{
              width: "235px",
              height: "235px",
              border: "1px solid #d9d9d9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <img
              src={imgSrc}
              alt="Product"
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          </div>
          <Upload name="image" listType="picture" beforeUpload={handleUpload}>
            <Button icon={<UploadOutlined />}>Upload ảnh</Button>
          </Upload>

          <h6 className="mt-3">Link Ảnh</h6>
          <Input
            name="linkAnh"
            value={values.linkAnh}
            size="large"
            placeholder="Link ảnh"
            prefix={<LinkOutlined />}
            onChange={handleChange}
          />
          <div className="text-danger">{errors.linkAnh}</div>

          <h6 className="mt-3">Tên Sản Phẩm</h6>
          <Input
            name="tenSanPham"
            value={values.tenSanPham}
            size="large"
            placeholder="Nhập Tên Sản Phẩm"
            onChange={handleChange}
          />
          <div className="text-danger">{errors.tenSanPham}</div>

          <h6 className="mt-3">Màu Sản Phẩm</h6>
          <Input
            name="mauSanPham"
            value={values.mauSanPham}
            size="large"
            placeholder="Nhập Màu Sản Phẩm"
            onChange={handleChange}
          />
          <div className="text-danger">{errors.mauSanPham}</div>

          <h6 className="mt-3">Giá Sản Phẩm Lấy Từ Web</h6>
          <InputNumber
            name="giaWeb"
            value={values.giaWeb}
            size="large"
            placeholder="Giá sản phẩm"
            prefix={<DollarOutlined />}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setFieldValue("giaWeb", value)}
            style={{ width: "100%" }}
          />
          <div className="text-danger">{errors.giaWeb}</div>

          <div className="row mt-3">
            <div className="col-md-4">
              <h6>Size</h6>
              <Input
                name="size"
                value={values.size}
                size="large"
                placeholder="Size"
                onChange={handleChange}
              />
              <div className="text-danger">{errors.size}</div>
            </div>
            <div className="col-md-4">
              <h6>Cân Nặng(Lạng)</h6>
              <InputNumber
                name="canNang"
                value={values.canNang}
                size="large"
                placeholder="Cân Nặng"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => setFieldValue("canNang", value)}
                style={{ width: "100%" }}
              />
              <div className="text-danger">{errors.canNang}</div>
            </div>

            <div className="col-md-4">
              <h6>Số Lượng</h6>
              <InputNumber
                name="soLuong"
                value={values.soLuong}
                size="large"
                placeholder="Số lượng"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                onChange={(value) => setFieldValue("soLuong", value)}
                style={{ width: "100%" }}
              />
              <div className="text-danger">{errors.soLuong}</div>
            </div>
          </div>

          <h6 className="mt-3">Code Off (%)</h6>
          <Input
            name="codeOff"
            value={values.codeOff}
            size="large"
            placeholder="Code off"
            onChange={handleChange}
          />
          <div className="text-danger">{errors.codeOff}</div>
        </div>

        <div className="col-md-6">
          <h6>
            Giá Báo Với Khách (VND) |{" "}
            <span style={{ color: "#3e81bc" }}>
              Tỉ Giá Hiện Tại :{" "}
              {values?.tiGiaHienTai?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
              })}
            </span>
            {" | "}
            <span style={{ color: "#3e81bc" }}>
              Giá Tham Khảo :{" "}
              {values?.giaThamKhao?.toLocaleString("vi-VN", {
                style: "currency",
                currency: "VND",
                minimumFractionDigits: 0,
              })}
            </span>
          </h6>
          <div className="row">
            <InputNumber
              className="col-md-7"
              name="giaBaoVoiKhach"
              value={values.giaBaoVoiKhach}
              size="large"
              placeholder="Giá báo với khách"
              prefix={<DollarOutlined />}
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              onChange={(value) => setFieldValue("giaBaoVoiKhach", value)}
            />
            <Button
              className="ms-md-2 col-md-4"
              type="primary"
              size="large"
              onClick={handleFetchReferencePrice}
              style={{ backgroundColor: "#5a5a5a", color: "white" }}
            >
              Giá tham khảo
            </Button>
          </div>
          <div className="text-danger">{errors.giaBaoVoiKhach}</div>

          <h6 className="mt-3">Ngày Hẹn Sản Phẩm Về</h6>
          <DatePicker
            style={{ width: "100%" }}
            placeholder="Chọn Ngày"
            format="DD/MM/YYYY"
            onChange={(date) => setFieldValue("ngayHenSanPham", date)}
          />
          <div className="text-danger">{errors.ngayHenSanPham}</div>

          <h6 className="mt-3">Hình Thức Thanh Toán</h6>
          <Select
            value={values.idHinhThucThanhToan}
            style={{ width: "100%" }}
            size="large"
            onChange={(value) => setFieldValue("idHinhThucThanhToan", value)}
            placeholder="Hình thức thanh toán"
          >
            <Option value={1}>Tiền mặt (COD)</Option>
            <Option value={2}>Chuyển khoản</Option>
          </Select>
          <div className="text-danger">{errors.idHinhThucThanhToan}</div>

          <h6 className="mt-3">Tiền Cọc (Nếu Có)</h6>
          <InputNumber
            name="tienCoc"
            value={values.tienCoc}
            size="large"
            placeholder="Tiền cọc"
            prefix={<DollarOutlined />}
            formatter={(value) =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={(value) => setFieldValue("tienCoc", value)}
            style={{ width: "100%" }}
          />
          <div className="text-danger">{errors.tienCoc}</div>

          <h6 className="mt-3">Ghi Chú</h6>
          <Input
            name="ghiChu"
            value={values.ghiChu}
            size="large"
            placeholder="Ghi chú"
            prefix={<EditOutlined />}
            onChange={handleChange}
          />
          <div className="text-danger">{errors.ghiChu}</div>

          <Divider />

          <h6>Thông Tin Về Khách Hàng</h6>

          <h6 className="mt-3">Số Điện Thoại</h6>
          <div className="row">
            <Input
              name="soDienThoai"
              value={values.soDienThoai}
              size="large"
              placeholder="Số điện thoại"
              onChange={handleChange}
              className="col-md-5"
            />
            <Button
              className="ms-md-2 col-md-6"
              type="primary"
              size="large"
              onClick={handleFetchCustomerInfo}
              style={{ backgroundColor: "#5a5a5a", color: "white" }}
            >
              Lấy thông tin khách hàng
            </Button>
          </div>
          <div className="text-danger">{errors.soDienThoai}</div>

          <h6 className="mt-3">Tên Khách Hàng</h6>
          <Input
            name="tenKhachHang"
            value={values.tenKhachHang}
            size="large"
            disabled
            placeholder=""
            onChange={handleChange}
          />
          <div className="text-danger">{errors.tenKhachHang}</div>

          <h6 className="mt-3">Link Facebook</h6>
          <Input
            name="linkFacebook"
            value={values.linkFacebook}
            size="large"
            placeholder=""
            disabled
            onChange={handleChange}
          />
          <div className="text-danger">{errors.linkFacebook}</div>

          <h6 className="mt-3">Địa Chỉ Ship Hàng</h6>
          <Input
            name="diaChiNhanHang"
            value={values.diaChiNhanHang}
            size="large"
            placeholder=""
            onChange={handleChange}
          />
          <div className="text-danger">{errors.diaChiNhanHang}</div>
        </div>
      </div>
      <Button
        htmlType="submit"
        size="large"
        className="mt-3"
        style={{
          width: "100%",
          backgroundColor: "#1890ff",
          color: "white",
        }}
      >
        Thêm Đơn Hàng
      </Button>
    </form>
  );
}

const FormikFormAddOrder = withFormik({
  mapPropsToValues: () => ({
    idNguonHang: "",
    linkSanPham: "",
    linkAnh: "",
    giaWeb: "",
    size: "",
    soLuong: "",
    codeOff: "",
    giaBaoVoiKhach: "",
    ngayHenSanPham: null,
    idHinhThucThanhToan: 1,
    soDienThoai: "",
    idKhachHang: "",
    tenKhachHang: "",
    linkFacebook: "",
    diaChiNhanHang: "",
    ghiChu: "",
    tienCoc: "",
    canNang: "",
    tenSanPham: "",
    mauSanPham: "",
    tiGiaHienTai: "",
    giaThamKhao: "",
    tienCong: "",
  }),

  validationSchema: Yup.object().shape({
    idNguonHang: Yup.string().required("Nguồn hàng không được để trống"),
    // linkSanPham: Yup.string().required("Link sản phẩm không được để trống"),
    // tenSanPham: Yup.string().required("Tên sản phẩm không được để trống"),
    linkAnh: Yup.string().required("Link ảnh không được để trống"),
    giaWeb: Yup.number()
      .required("Giá sản phẩm không được để trống")
      .min(0, "Giá phải lớn hơn hoặc bằng 0"),
    size: Yup.string().required("Size không được để trống"),
    soLuong: Yup.number()
      .required("Số lượng không được để trống")
      .min(0, "Số lượng phải lớn hơn hoặc bằng 0"),
    codeOff: Yup.number()
      .min(0, "Code giảm giá phải lớn hơn hoặc bằng 0")
      .max(100, "Code giảm giá phải nhỏ hơn hoặc bằng 100"),
    giaBaoVoiKhach: Yup.number()
      .required("Giá báo với khách không được để trống")
      .min(0, "Giá báo phải lớn hơn hoặc bằng 0"),
    ngayHenSanPham: Yup.date().required("Ngày hẹn không được để trống"),
    idHinhThucThanhToan: Yup.string().required(
      "Hình thức thanh toán không được để trống"
    ),
    soDienThoai: Yup.string()
      .required("Số điện thoại không được để trống")
      .matches(/^\d+$/, "Số điện thoại phải là số"),
    canNang: Yup.number().min(0, "Cân nặng phải lớn hơn hoặc bằng 0"),
    ghiChu: Yup.string(),
    tienCoc: Yup.number().min(0, "Tiền cọc phải lớn hơn hoặc bằng 0"),
    tenKhachHang: Yup.string().required("bạn chưa lấy thông tin Khách hàng"),
    diaChiNhanHang: Yup.string().required("Địa Chỉ nhận Hàng là bắt buộc!"),
  }),

  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    const formattedDate = dayjs(values.ngayHenSanPham).format("YYYY/MM/DD");

    const formData = {
      ...values,
      ngayHenTraSanPham: formattedDate, // Gán giá trị mới
    };

    props.dispatch({
      type: THEM_DON_HANG_SAGA,
      newDonHang: formData,
      callback: (success) => {
        if (success) {
          resetForm({
            values: {
              idNguonHang: "",
              linkSanPham: "",
              linkAnh: "",
              giaWeb: "",
              size: "",
              soLuong: "",
              codeOff: "",
              giaBaoVoiKhach: "",
              idHinhThucThanhToan: 1,
              soDienThoai: "",
              idKhachHang: "",
              tenKhachHang: "",
              linkFacebook: "",
              diaChiNhanHang: "",
              ghiChu: "",
              tienCoc: "",
              canNang: "",
              tenSanPham: "",
              mauSanPham: "",
              tiGiaHienTai: "",
              ngayHenSanPham: values.ngayHenSanPham, // Giữ lại giá trị ngayHenSanPham
            },
          });
        }
      },
    });
  },
})(FormAddOrder);

export default connect()(FormikFormAddOrder);
