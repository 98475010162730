export const SET_DANH_SACH_NUON_HANG_TRANG_NHAN_DON = 'SET_DANH_SACH_NUON_HANG_TRANG_NHAN_DON'

export const LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA = 'LAY_DANH_SACH_NUON_HANG_TRANG_NHAN_DON_SAGA'


export const SET_DANH_SACH_ORDER_TRANG_NHAN_DON = 'SET_DANH_SACH_ORDER_TRANG_NHAN_DON'
export const LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA = 'LAY_DANH_SACH_ORDER_TRANG_NHAN_DON_SAGA'


export const SET_DANH_SACH_ORDER_CHON_NHAN_DON = 'SET_DANH_SACH_CHON_NHAN_DON'
export const LAY_DANH_SACH_CHON_NHAN_DON_SAGA = 'LAY_DANH_SACH_CHON_NHAN_DON_SAGA'

export const SET_DON_HANG_EDIT_NHAN_DON = 'SET_DON_HANG_EDIT_NHAN_DON'

export const CHINH_SUA_DON_HANG_ORDER_NHAN_DON_SAGA = 'CHINH_SUA_DON_HANG_ORDER_NHAN_DON_SAGA'

export const XAC_NHAN_DA_NHAN_HANG_SAGA = 'XAC_NHAN_DA_NHAN_HANG_SAGA'

export const THAY_DOI_TONG_CAN_NANG_SAGA = 'THAY_DOI_TONG_CAN_NANG_SAGA'