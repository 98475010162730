import React from "react";
import { useSelector } from "react-redux";

const ShippingOrderPrint = ({ order }) => {
  const { danhSachDonHangDaChon } = useSelector(
    (state) => state?.DonShipReducer
  );

  let TongTien = 0;
  let tienCoc = 0;
  for (let i = 0; i < danhSachDonHangDaChon?.length; i++) {
    TongTien +=
      danhSachDonHangDaChon[i]?.GiaBaoVoiKhach +
      danhSachDonHangDaChon[i]?.TongTienCan;
    tienCoc += danhSachDonHangDaChon[i]?.TienCoc;
  }
  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <div className="row align-items-center" style={{ padding: "20px 0" }}>
        <div className="col-4 d-flex justify-content-center">
          <img
            src={require("../assets/imageLogo/logo.jpg")}
            alt="Logo"
            style={{ width: "200px" }}
          />
        </div>
        <div className="col-8 text-center">
          <h1 style={{ fontWeight: "bold", color: "#333" }}>Mẹ Gấu Order</h1>
          <h2 style={{ fontWeight: "bold", color: "#333" }}>Authentic</h2>
          <p style={{ fontWeight: "bold", fontSize: "18px", color: "#333" }}>
            0944.089.919
          </p>
        </div>
      </div>

      <div className="mt-3">
        <p>
          <strong>Tên khách hàng:</strong> {order?.tbKhachHang?.TenKhachHang}
        </p>
        <p>
          <strong>Địa chỉ:</strong> {order?.DiaChiNhanHang}
        </p>
        <p>
          <strong>Số điện thoại:</strong> {order?.tbKhachHang?.SoDienThoai}
        </p>
      </div>
      <table
        style={{ width: "100%", borderCollapse: "collapse", marginTop: "20px" }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>#</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Sản phẩm
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Giá web
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Cân nặng
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Số lượng
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Thành tiền
            </th>
          </tr>
        </thead>
        <tbody>
          {danhSachDonHangDaChon.map((item, index) => (
            <tr key={index}>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {item?.TenSanPham}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "right",
                }}
              >
                {(
                  (item?.GiaBaoVoiKhach + item?.TongTienCan) /
                  item?.SoLuong
                )?.toLocaleString("vi-VN", {
                  style: "currency",
                  currency: "VND",
                  minimumFractionDigits: 0,
                })}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {item?.CanNang?.toLocaleString("vi-VN")}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {item?.SoLuong?.toLocaleString("vi-VN")}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "right",
                }}
              >
                {(item?.GiaBaoVoiKhach + item?.TongTienCan)?.toLocaleString(
                  "vi-VN",
                  {
                    style: "currency",
                    currency: "VND",
                    minimumFractionDigits: 0,
                  }
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div style={{ textAlign: "right", marginTop: "20px" }}>
        <strong>
          Tổng tiền:{" "}
          {TongTien?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
          })}
        </strong>
      </div>
      <div style={{ textAlign: "right", marginTop: "5px" }}>
        <strong>
          Tiền Cọc:{" "}
          {tienCoc?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
          })}
        </strong>
      </div>
      <div style={{ textAlign: "right", marginTop: "5px" }}>
        <strong>
          Còn Lại:{" "}
          {(TongTien - tienCoc)?.toLocaleString("vi-VN", {
            style: "currency",
            currency: "VND",
            minimumFractionDigits: 0,
          })}
        </strong>
      </div>
      <p style={{ textAlign: "center", marginTop: "40px" }}>
        Xín chân thành cảm ơn quý khách ...!!!
      </p>
    </div>
  );
};

export default ShippingOrderPrint;
