import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  DatePicker,
  Checkbox,
  Input,
  InputNumber,
  Modal,
} from "antd";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import { Lay_DANH_SACH_NGUON_HANG_SAGA } from "../../redux/types/NguonHangType";
import {
  RESET_ORDER,
  SET_DANH_SACH_DON_HANG_ORDER_SAGA,
  TAO_ORDER_SAGA,
  THEM_DON_HANG_DA_CHON,
  XOA_DON_HANG_DA_CHON,
} from "../../redux/types/OderType";
import { orderService } from "../../services/orderService";
import { Notification } from "../../utils/Notifications/Notification";
import { NOTIFICATION_TYPE } from "../../utils/settings/config";

const { RangePicker } = DatePicker;

export default function TaoMoiOrder() {
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentRecord, setCurrentRecord] = useState(null);

  const handleOpenModal = (record) => {
    setCurrentRecord(record);
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
    setCurrentRecord(null);
  };
  useEffect(() => {
    dispatch({ type: RESET_ORDER });
  }, []);

  const {
    danhSachDonHang,
    totalCount,
    danhSachDonHangDaChon,
    danhSachTinhTrangDonHang,
  } = useSelector((state) => state.OrderReducer);

  const [orderInfo, setOrderInfo] = useState({
    maOrder: "",
    trackingNumber: "",
    soTien: "",
    shipWeb: "",
    ngayNhanHangDuKien: null,
    tiGia: "",
    nguonHang: "",
  });

  const { danhSachNguonHang } = useSelector((state) => state.NguonHangReducer);

  const [filters, setFilters] = useState({
    label: "",
    orderStatus: 1,
    startDate: "",
    endDate: "",
  });

  const handleResetSearch = () => {
    setFilters({
      label: "",
      orderStatus: 1,
      startDate: "",
      endDate: "",
    });
    handleSearch();
  };

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const [showTable, setShowTable] = useState(true);
  const [showTableChon, setShowTableChon] = useState(true);

  useEffect(() => {
    dispatch({ type: Lay_DANH_SACH_NGUON_HANG_SAGA });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_DANH_SACH_DON_HANG_ORDER_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  }, [pagination, dispatch]);

  const handleSearch = () => {
    const newPagination = { ...pagination, current: 1 }; // Reset to first page on new search
    setPagination(newPagination); // Update the pagination state

    // Dispatch the action with new pagination and filters
    dispatch({
      type: SET_DANH_SACH_DON_HANG_ORDER_SAGA,
      page: pagination.current,
      pageSize: pagination.pageSize,
      filters,
    });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const handleGetTiGia = async () => {
    try {
      const { data, status } = await orderService.layTiGiaAPI(
        danhSachDonHangDaChon[0]?.IdNguonHang
      );
      setOrderInfo({ ...orderInfo, tiGia: data?.data });
    } catch (error) {
      Notification(NOTIFICATION_TYPE.error, "lấy tỉ giá thất bại");
    }
  };

  const handleSelectOrder = (record) => {
    const exists = danhSachDonHangDaChon.find(
      (order) => order.Id === record.Id
    );
    if (exists) {
      dispatch({ type: XOA_DON_HANG_DA_CHON, payload: record });
    } else {
      dispatch({ type: THEM_DON_HANG_DA_CHON, payload: record });
    }
  };

  const handleRemoveSelectedOrder = (record) => {
    dispatch({ type: XOA_DON_HANG_DA_CHON, payload: record });
  };

  const formatCurrency = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      style: "currency",
      currency: "VND",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
      .format(value)
      .replace(/\./g, ",");
  };

  const formatInteger = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(value);
  };

  const formatDecimal = (value) => {
    if (value === null || value === undefined) return "";

    return new Intl.NumberFormat("vi-VN", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  const columns = [
    // {
    //   title: "Mã Đơn Hàng",
    //   dataIndex: "MaDonHang",
    //   key: "MaDonHang",
    // },
    // {
    //   title: "Nguồn Hàng",
    //   dataIndex: "TenNguonHang",
    //   key: "TenNguonHang",
    // },
    {
      title: "Mã",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
      render: (text, record) => {
        return (
          <>
            <div>{record?.MaDonHang}</div>
            <div>{record?.TenNguonHang}</div>
          </>
        );
      },
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div
            onClick={() => handleOpenModal(record)}
            style={{
              cursor: "pointer",
              width: "90px",
              height: "90px",
              border: "1px solid #d9d9d9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <img
              src={record?.LinkAnh}
              alt="Product"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>

          {/* Modal */}
          <Modal
            title="Thông Tin Sản Phẩm"
            visible={isModalVisible}
            onCancel={handleCloseModal}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={currentRecord?.LinkAnh}
                alt="Product"
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  marginBottom: "20px",
                }}
              />
              <br />
              <a
                target="_blank"
                href={currentRecord?.LinkSanPham}
                rel="noreferrer"
              >
                Link đến sản phẩm
              </a>
            </div>
          </Modal>
        </div>
      ),
    },
    ,
    // {
    //   title: "Thông Tin Sản phẩm",
    //   key: "LinkSanPham",
    //   width: 200,
    //   render: (text, record) => (
    //     <div>
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <div style={{ marginLeft: "10px" }}>
    //           <div>
    //             Tên SP:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.TenSanPham}
    //             </span>
    //           </div>
    //           <div>
    //             Màu:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.MauSanPham}
    //             </span>
    //           </div>
    //           <div>
    //             Size:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.Size}
    //             </span>
    //           </div>
    //           <div>
    //             Cân Nặng:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {formatDecimal(record?.CanNang)}
    //             </span>
    //           </div>
    //           <div>
    //             Số Lượng:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {formatInteger(record?.SoLuong)}
    //             </span>
    //           </div>
    //           <div>
    //             Giá Web:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {formatInteger(record?.GiaWeb)}
    //             </span>
    //           </div>
    //           <div>
    //             CodeOff:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.CodeOff}%
    //             </span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ),
    //   align: "left",
    // },
    {
      title: "Sản phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div>
            <div>
              <div>
                <span style={{ fontWeight: "bold" }}>{record?.TenSanPham}</span>
                {" | "}
                <span style={{ fontWeight: "bold" }}>
                  {record?.MauSanPham}
                </span>{" "}
                {" | "}
                <span style={{ fontWeight: "bold" }}>{record?.Size}</span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
                {" gam | SL: "}
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>{" "}
                {record?.tbNguonHang?.tbTienTe?.TienTe} | CodeOff:{" "}
                <span style={{ fontWeight: "bold" }}>{record?.CodeOff}%</span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div>
              Giá:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div>
              Cọc:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div>
              Cân:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Tình Trạng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              {record?.IdTinhTrang === 3 ||
              record?.IdTinhTrang === 4 ||
              record?.IdTinhTrang === 8 ||
              record?.IdTinhTrang === 9 ? (
                <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
              ) : (
                <span>{text}</span>
              )}
            </div>
            <div>
              Ngày Lên:
              <span className="fw-bold">
                {dayjs(record?.NgayTao).format("DD/MM/YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    // {
    //   title: "Ngày Lên Đơn",
    //   dataIndex: "NgayTao",
    //   key: "NgayTao",
    //   render: (text) => dayjs(text).format("DD/MM/YYYY"),
    // },
    {
      title: "Chọn",
      key: "Select",
      render: (text, record) => (
        <Checkbox
          checked={
            !!danhSachDonHangDaChon.find((order) => order.Id === record.Id)
          }
          onChange={() => handleSelectOrder(record)}
        />
      ),
    },
  ];

  const selectedOrderColumns = [
    {
      title: "Mã",
      dataIndex: "MaDonHang",
      key: "MaDonHang",
      render: (text, record) => {
        return (
          <>
            <div>{record?.MaDonHang}</div>
            <div>{record?.TenNguonHang}</div>
          </>
        );
      },
    },
    {
      title: "Ảnh Sản Phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div
            onClick={() => handleOpenModal(record)}
            style={{
              cursor: "pointer",
              width: "90px",
              height: "90px",
              border: "1px solid #d9d9d9",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <img
              src={record?.LinkAnh}
              alt="Product"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </div>

          {/* Modal */}
          <Modal
            title="Thông Tin Sản Phẩm"
            visible={isModalVisible}
            onCancel={handleCloseModal}
            footer={null}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={currentRecord?.LinkAnh}
                alt="Product"
                style={{
                  maxWidth: "100%",
                  maxHeight: "300px",
                  marginBottom: "20px",
                }}
              />
              <br />
              <a
                target="_blank"
                href={currentRecord?.LinkSanPham}
                rel="noreferrer"
              >
                Link đến sản phẩm
              </a>
            </div>
          </Modal>
        </div>
      ),
    },
    ,
    // {
    //   title: "Thông Tin Sản phẩm",
    //   key: "LinkSanPham",
    //   width: 200,
    //   render: (text, record) => (
    //     <div>
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <div style={{ marginLeft: "10px" }}>
    //           <div>
    //             Tên SP:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.TenSanPham}
    //             </span>
    //           </div>
    //           <div>
    //             Màu:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.MauSanPham}
    //             </span>
    //           </div>
    //           <div>
    //             Size:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.Size}
    //             </span>
    //           </div>
    //           <div>
    //             Cân Nặng:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {formatDecimal(record?.CanNang)}
    //             </span>
    //           </div>
    //           <div>
    //             Số Lượng:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {formatInteger(record?.SoLuong)}
    //             </span>
    //           </div>
    //           <div>
    //             Giá Web:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {formatInteger(record?.GiaWeb)}
    //             </span>
    //           </div>
    //           <div>
    //             CodeOff:{" "}
    //             <span style={{ fontWeight: "bold", color: "#3498db" }}>
    //               {record?.CodeOff}%
    //             </span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   ),
    //   align: "left",
    // },
    {
      title: "Sản phẩm",
      key: "LinkSanPham",
      render: (text, record) => (
        <div>
          <div>
            <div>
              <div>
                <span style={{ fontWeight: "bold" }}>{record?.TenSanPham}</span>
                {" | "}
                <span style={{ fontWeight: "bold" }}>
                  {record?.MauSanPham}
                </span>{" "}
                {" | "}
                <span style={{ fontWeight: "bold" }}>{record?.Size}</span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatDecimal(record?.CanNang)}
                </span>
                {" gam | SL: "}
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.SoLuong)}
                </span>
              </div>

              <div>
                <span style={{ fontWeight: "bold" }}>
                  {formatInteger(record?.GiaWeb)}
                </span>{" "}
                {record?.tbNguonHang?.tbTienTe?.TienTe} | CodeOff:{" "}
                <span style={{ fontWeight: "bold" }}>{record?.CodeOff}%</span>
              </div>
            </div>
          </div>
        </div>
      ),
      align: "left",
    },
    {
      title: "Thanh Toán",
      dataIndex: "GiaBaoVoiKhach",
      key: "GiaBaoVoiKhach",
      render: (text, record) => {
        const GiaBaoVoiKhach = parseFloat(record?.GiaBaoVoiKhach).toFixed(1);
        const TienCoc = parseFloat(record?.TienCoc).toFixed(1);
        const TongTienCan = parseFloat(record?.TongTienCan).toFixed(2);
        return (
          <>
            <div>
              Giá:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(GiaBaoVoiKhach)}
                </span>
              </span>
            </div>
            <div>
              Cọc:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TienCoc)}
                </span>
              </span>
            </div>
            <div>
              Cân:{" "}
              <span>
                <span
                  style={{
                    fontWeight: "bold",
                    color: "inherit",
                    fontSize: "14px",
                  }}
                >
                  {formatCurrency(TongTienCan)}
                </span>
              </span>
            </div>
          </>
        );
      },
      align: "left",
    },
    {
      title: "Tình Trạng",
      dataIndex: "TinhTrangDonHang",
      key: "TinhTrangDonHang",
      render: (text, record) => {
        return (
          <>
            <div>
              {record?.IdTinhTrang === 3 ||
              record?.IdTinhTrang === 4 ||
              record?.IdTinhTrang === 8 ||
              record?.IdTinhTrang === 9 ? (
                <span style={{ color: "red", fontWeight: "bold" }}>{text}</span>
              ) : (
                <span>{text}</span>
              )}
            </div>
            <div>
              Ngày Lên:
              <span className="fw-bold">
                {dayjs(record?.NgayTao).format("DD/MM/YYYY")}
              </span>
            </div>
          </>
        );
      },
    },
    {
      title: "Hành Động",
      key: "action",
      render: (text, record) => {
        return (
          <div>
            <a
              target="_blank"
              href={record?.LinkSanPham}
              className="btn btn-primary me-2"
            >
              <i className="fa-solid fa-cart-plus"></i>
            </a>
            <button
              className="btn btn-danger"
              type="link"
              onClick={() => handleRemoveSelectedOrder(record)}
            >
              <i className="fa-solid fa-trash"></i> Hủy Chọn
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <h3 className="col-md-9">Tạo Order</h3>

      <div className="search-filters my-4 row">
        <div className="col-md-2">
          <h6>Chọn Nguồn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            value={filters.label}
            onChange={(value) => setFilters({ ...filters, label: value })}
            placeholder="Chọn nguồn hàng"
          >
            {danhSachNguonHang?.map((item, key) => (
              <Select.Option key={key} value={item?.Id}>
                {item?.Ten}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="col-md-3 ml-md-2">
          <h6>Tình Trạng Đơn Hàng</h6>
          <Select
            style={{ width: "100%" }}
            placeholder="Tình trạng đơn hàng"
            value={filters.orderStatus}
            onChange={(value) => setFilters({ ...filters, orderStatus: value })}
          >
            {danhSachTinhTrangDonHang?.map((item, key) => {
              return (
                <Select.Option value={item?.Id} key={key}>
                  {item?.Ten}
                </Select.Option>
              );
            })}
          </Select>
        </div>

        <div className="col-md-4 me-2">
          <h6>Chọn Ngày</h6>
          <RangePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            onChange={(dates) => {
              setFilters({
                ...filters,
                startDate:
                  dates && dates[0] ? dates[0].format("YYYY-MM-DD") : null,
                endDate:
                  dates && dates[1] ? dates[1].format("YYYY-MM-DD") : null,
              });
            }}
          />
        </div>

        {/* <Button className="col-2 ml-3" type="primary" onClick={handleSearch}>
          Tìm Kiếm Đơn Hàng
        </Button> */}

        <div className="col-md-3 row mt-3">
          <Button className="col-6" type="primary" onClick={handleSearch}>
            Tìm Kiếm
          </Button>
          <Button
            className="col-5 ms-2"
            type="default"
            onClick={handleResetSearch}
          >
            Hủy Tìm Kiếm
          </Button>
        </div>
      </div>

      <div className="w-100">
        <Button
          type="primary"
          onClick={() => setShowTable(!showTable)}
          style={{ marginBottom: "20px" }}
        >
          {showTable ? "Ẩn Bảng Chọn Đơn Hàng" : "Hiện Bảng Chọn Đơn Hàng"}
        </Button>
      </div>

      {showTable && (
        <Table
          columns={columns}
          rowKey={"id"}
          dataSource={danhSachDonHang}
          pagination={{
            ...pagination,
            total: totalCount,
          }}
          onChange={handleTableChange}
        />
      )}

      <h4>Danh Sách Đơn Hàng Đã Chọn:</h4>
      <div className="w-100">
        <Button
          type="primary"
          onClick={() => setShowTableChon(!showTableChon)}
          style={{ marginBottom: "20px" }}
        >
          {showTableChon
            ? "Ẩn Bảng Đơn Hàng Đã Chọn"
            : "Hiện Bảng Đơn Hàng Đã Chọn"}
        </Button>
      </div>
      {showTableChon && (
        <Table
          columns={selectedOrderColumns}
          rowKey={"Id"}
          dataSource={danhSachDonHangDaChon}
          pagination={false} // Disable pagination for selected orders table
        />
      )}

      <div className="mt-4">
        <h4>Thông Tin Order</h4>
        <div className="row">
          <div className="col-md-4">
            <h6>Nguồn Hàng</h6>
            <Select
              disabled
              style={{ width: "100%" }}
              value={danhSachDonHangDaChon[0]?.IdNguonHang}
              onChange={(value) =>
                setOrderInfo({ ...orderInfo, nguonHang: value })
              }
              placeholder="Chọn nguồn hàng"
            >
              {danhSachNguonHang?.map((item, key) => (
                <Select.Option key={key} value={item?.Id}>
                  {item?.Ten}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div className="col-md-4">
            <h6>Mã Order</h6>
            <Input
              value={orderInfo.maOrder}
              onChange={(e) =>
                setOrderInfo({ ...orderInfo, maOrder: e.target.value })
              }
              placeholder="Nhập mã order"
            />
          </div>

          <div className="col-md-4">
            <h6>Tracking Number</h6>
            <Input
              value={orderInfo.trackingNumber}
              onChange={(e) =>
                setOrderInfo({ ...orderInfo, trackingNumber: e.target.value })
              }
              placeholder="Nhập tracking number"
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-4">
            <h6>Số Tiền(VND)</h6>
            <InputNumber
              style={{ width: "100%" }}
              value={orderInfo.soTien}
              onChange={(value) =>
                setOrderInfo({ ...orderInfo, soTien: value })
              }
              formatter={(value) =>
                `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              placeholder="Nhập số tiền"
            />
          </div>

          <div className="col-md-4">
            <h6>Ngày Nhận Hàng Dự Kiến</h6>
            <DatePicker
              style={{ width: "100%" }}
              format={"DD/MM/YYYY"}
              value={
                orderInfo.ngayNhanHangDuKien
                  ? dayjs(orderInfo.ngayNhanHangDuKien)
                  : null
              }
              onChange={(date) =>
                setOrderInfo({
                  ...orderInfo,
                  ngayNhanHangDuKien: date ? date.format("YYYY-MM-DD") : null,
                })
              }
              placeholder="Chọn ngày nhận hàng dự kiến"
            />
          </div>

          <div className="col-md-4">
            <h6>Tỉ Giá Hiện Tại (VND)</h6>
            <div className="row">
              <InputNumber
                className="col-md-7"
                value={orderInfo.tiGia}
                onChange={(value) =>
                  setOrderInfo({ ...orderInfo, tiGia: value })
                }
                placeholder="Nhập tỉ giá"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
              />
              <Button
                className="col-md-4 ms-md-2"
                type="primary"
                onClick={handleGetTiGia}
                style={{
                  backgroundColor: "#5a5a5a",
                  color: "white",
                }}
              >
                Lấy Tỉ Giá Tham Khảo
              </Button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-4">
              <h6>Ship web(VND)</h6>
              <InputNumber
                style={{ width: "100%" }}
                value={orderInfo.shipWeb}
                onChange={(value) =>
                  setOrderInfo({ ...orderInfo, shipWeb: value })
                }
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }
                parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                placeholder="Nhập ship web"
              />
            </div>
          </div>
        </div>
      </div>

      <Button
        htmlType="submit"
        size="large"
        className="mt-3"
        style={{
          width: "100%",
          backgroundColor: "#1890ff",
          color: "white",
        }}
        onClick={() => {
          dispatch({
            type: TAO_ORDER_SAGA,

            orderInfo,
            idNguonHang: danhSachDonHangDaChon[0]?.IdNguonHang,
            danhSachDonHang: danhSachDonHangDaChon,
          });
        }}
      >
        Tạo Order
      </Button>
    </div>
  );
}
